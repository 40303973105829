import Container, { Service } from 'typedi';
import { ClientsFrameworkLibrary } from 'library-manager';

import { LoggerProvider } from '../../logger';
import { IClientsFrameworkRegistrationService } from './interfaces';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class ClientsFrameworkRegistrationService implements IClientsFrameworkRegistrationService {
    private readonly _logger: ILogger;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger(
            'ClientsFrameworkRegistrationService',
        );
    }

    public Open = async (
        width,
        height,
        dlParam?: any,
        lang?: string,
        onCloseCallback?: Function,
    ) => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.Registration.Open(
                width,
                height,
                dlParam,
                lang,
                onCloseCallback,
            ),
        );
    };

    public FYP = async (width, height) => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.Registration.FYP(width, height),
        );
    };

    public FYU = async (width, height) => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.Registration.FYU(width, height),
        );
    };
}
