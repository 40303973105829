import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { ICrossSellChannel, ILogger, MessageBroker } from '@sparkware/uc-sdk-core';
import { ICrossSell } from '../../cross-sell/models/ICrossSell';
import { LoaderManager } from '../../../loaders/LoaderManager';

@Service()
export class CrossSellChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _crossSellChannel: ICrossSellChannel;

    private get _crossSellPromise(): Promise<ICrossSell> {
        return LoaderManager.Instance.CrossSellLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('CrossSellChannelSubscriber');
        this._crossSellChannel = MessageBroker.getInstance().crossSell;
        this._crossSellChannel.topics.CTA_CasinoClient_Open.subscribe(
            this.onCasinoClientOpen.bind(this),
        );
        this._crossSellChannel.topics.CTA_SportClient_Open.subscribe(
            this.onSportClientOpen.bind(this),
        );
        this._crossSellChannel.topics.CTA_LiveDealer_Open.subscribe(
            this.onLiveDealerOpen.bind(this),
        );
        this._crossSellChannel.topics.CTA_Vegas_Open.subscribe(this.onVegasOpen.bind(this));
        this._crossSellChannel.topics.CTA_PokerClient_Open.subscribe(
            this.onPokerClientOpen.bind(this),
        );
    }

    private async onCasinoClientOpen(data: any) {
        const crossSell = await this._crossSellPromise;
        await crossSell?.onCasinoClientOpen(data);
    }

    private async onSportClientOpen(data: any) {
        const crossSell = await this._crossSellPromise;
        crossSell?.onSportClientOpen(data);
    }

    private async onLiveDealerOpen() {
        const crossSell = await this._crossSellPromise;
        crossSell?.onLiveDealerOpen();
    }

    private async onVegasOpen() {
        const crossSell = await this._crossSellPromise;
        crossSell?.onVegasOpen();
    }

    private async onPokerClientOpen() {
        const crossSell = await this._crossSellPromise;
        crossSell?.onPokerClientOpen();
    }
}
