import Container, { Service } from 'typedi';
import PromotionEligibilityApi, {
    IGetPromotionEligibilityRequest,
    IGetPromotionEligibilityResponse,
} from '../../../APIs/PromotionEligibilityApi';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import { LoggerProvider } from '../logger';

import { SessionSimpleStoreService } from '../storage/implementations/simple-store';

import {
    MessageBroker,
    ICrmChannel,
    IPromotionEligibilityUpdateData,
    ILogger,
} from '@sparkware/uc-sdk-core';
import { B2CIntegration } from '../b2c-integration';
import { IPromotionEligibilityMessageData } from '../push/models/IPromotionEligibilityMessageData';

@Service()
export class PromotionEligibilityService {
    private _logger: ILogger;

    private readonly _sessionStoreItemService: SessionSimpleStoreService;
    private readonly _channel: ICrmChannel;
    private readonly _b2cIntegration: B2CIntegration;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('PromotionEligibilityService');

        this._sessionStoreItemService = Container.get(SessionSimpleStoreService);
        this._channel = MessageBroker.getInstance().crm;
        this._b2cIntegration = Container.get(B2CIntegration);
    }

    public checkPromotionEligibilityApiAndPublish = async (): Promise<boolean> => {
        //Do API Call
        let cId = this._sessionStoreItemService.get(StorageItemEnum.CID);

        const reqParam: IGetPromotionEligibilityRequest = { cId: cId };
        const { response, errorResponse } = await PromotionEligibilityApi.Get(reqParam);

        if (response) {
            this._logger.info(
                `Eligibility Data(Poker Free Spin) from BFF: ${JSON.stringify({ response })}`,
            );

            //Publish an update
            const updateModel = this._mapFromApiModel(response);
            this.publishPromotionEligibilityData(updateModel);
        } else {
            this._logger.error(
                "Eligibility(Poker Free Spin) - couldn't fetch data.\n Error Response:",
                errorResponse,
            );
        }
        return true;
    };

    //this may be called after API call or FireStore Event
    public publishPromotionEligibilityData = (
        // result: Array<IGetPromotionEligibilityResponse>,
        updateModel: IPromotionEligibilityUpdateData,
    ): void => {
        //publish it for UAA
        this._channel.topics.ELIGIBILITY_DATA.publish({ publisher: 'UCF.SDK - CRM' }, updateModel);

        //publish to B2C Integration  - player segmentation
        this._b2cIntegration.playerSegmentationPublish(updateModel);
    };

    //FireStore Response mapping:
    public mapFromFireStoreModel(
        fireStoreResponse: IPromotionEligibilityMessageData,
    ): IPromotionEligibilityUpdateData {
        this._logger.info(
            `Eligibility Data(Poker Free Spin) from FireStore: ${JSON.stringify({
                fireStoreResponse,
            })}`,
        );

        //empty response - return false
        if (fireStoreResponse.IsEligible === false) {
            return {
                isEligible: false,
            };
        }

        return {
            isEligible: true,
            timerCounter: fireStoreResponse.TimerCounterInMinutes,
        };
    }

    //API Response mapping:
    private _mapFromApiModel(
        apiResponse: IGetPromotionEligibilityResponse[],
    ): IPromotionEligibilityUpdateData {
        //even though it is an array, there should only be response regarding free spin eligibility (no planns for anything else ATM)

        //empty response - return false
        if (!apiResponse?.length) {
            return {
                isEligible: false,
            };
        }

        let freeSpinResponse = apiResponse[0];
        return {
            isEligible: true,
            timerCounter: freeSpinResponse.timerCounterInMinutes,
        };
    }
}
