import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import {
    IIinitLoyaltyIindicationData,
    ILogger,
    ILoyaltyIndicationChannel,
    ITopicEnvelope,
    MessageBroker,
} from '@sparkware/uc-sdk-core';
import { ILoyaltyIndication } from '../../loyalty-indication/models/ILoyaltyIndication';
import { LoaderManager } from '../../../loaders/LoaderManager';

@Service()
export class LoyaltyIndicationChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _loyaltyIndicationChannel: ILoyaltyIndicationChannel;

    private get _loyaltyIndicationPromise(): Promise<ILoyaltyIndication> {
        return LoaderManager.Instance.LoyaltyIndicationLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('LoyaltyIndicationSubscriber');
        this._loyaltyIndicationChannel = MessageBroker.getInstance().loyaltyIndication;
        this._loyaltyIndicationChannel.topics.INIT_LOYALTY_INDICATION.subscribe(
            this.onInitLoyaltyIndication.bind(this),
        );
    }

    private async onInitLoyaltyIndication(
        data: IIinitLoyaltyIindicationData,
        envelope: ITopicEnvelope,
    ) {
        const loyaltyIndication = await this._loyaltyIndicationPromise;
        loyaltyIndication?.onInitLoyaltyIndication(data, envelope);
    }
}
