import { MessageBroker } from '@sparkware/uc-sdk-core';
import Container from 'typedi';
import { LoggerProvider } from '../../../logger';
import DialogType from '../../../ui/enums/DialogType';

export const initMessageBroker = (className: string) => {
    try {
        const logger = Container.get(LoggerProvider).getLogger(className);
        MessageBroker.init(logger);
        const channels = MessageBroker.getInstance();
        channels.ui.dialogType = DialogType;
        return channels;
    } catch (err) {
        console.error('initMessageBroker failed');
    }
};
