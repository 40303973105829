import { Container, Service } from 'typedi';
import { LoggerProvider } from '../logger';
import {
    SDKStateEnum,
    AppStateEnum,
    SequentialCorrelationIdHandler,
    IAppLaunchInfo,
    AppIdentifiers,
    OpeningMode,
    ILogger,
} from '@sparkware/uc-sdk-core';
import { NativeUtils } from '../native/native.utils';
import { Utils } from '../utils';
import { IAppCatalog } from '../app-catalog/interfaces';
import { LoaderManager } from '../../loaders/LoaderManager';

export interface IAppState {
    state: AppStateEnum;
    applicationLayerLevel: number;
}

@Service()
export class AppState {
    private _sdkState: Record<string, SDKStateEnum> = {};
    private _appState: Record<string, IAppState> = {};
    private _seqCorrIdHandlerDict: Record<string, SequentialCorrelationIdHandler> = {};
    private _openedWidgets: Record<AppIdentifiers, IAppLaunchInfo> = {} as any;
    private readonly _logger: ILogger;
    private readonly _utils: Utils;

    private get _appCatalogPromise(): Promise<IAppCatalog> {
        return LoaderManager.Instance.AppCatalogLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('AppState');
        this._utils = Container.get(Utils);
    }

    public getSDKState = (sdkName: string): SDKStateEnum => {
        const state = this._sdkState[sdkName] || SDKStateEnum.None;
        this._logger.debug(`getSDKState: sdkName: ${sdkName} | state: ${state}`);
        return state;
    };

    public setSDKState = (sdkName: string, state: SDKStateEnum): void => {
        this._logger.debug(`setSDKState: sdkName: ${sdkName} | state: ${state}`);
        this._sdkState[sdkName] = state;
    };

    public getAppState = (appID: string): IAppState => {
        const appState = this._appState[appID];
        this._logger.debug(
            `getAppState: appID: ${appID} | state: ${
                appState?.state || '-'
            } | applicationLayerLevel: ${appState?.applicationLayerLevel || '-'}`,
        );
        return appState;
    };

    public setAppState = (
        appID: string,
        state: AppStateEnum,
        applicationLayerLevel: number = this.getApplicationLayerLevel(appID),
    ) => {
        this._logger.debug(
            `setAppState: appID: ${appID} | state: ${state} | applicationLayerLevel: ${applicationLayerLevel}`,
        );
        this._appState[appID] = { state, applicationLayerLevel };
    };

    public getActiveApp = (): string => {
        const activeApp = Object.keys(this._appState).find(
            (key) => this._appState[key].state === AppStateEnum.Active,
        );
        this._logger.debug(`getActiveApp: appID: ${activeApp}`);
        return activeApp;
    };

    /**
     * deprecated, use getAppsByState instead.
     */
    public getActiveApps = (): string[] => {
        const activeApps = Object.keys(this._appState).filter(
            (key) => this._appState[key].state === AppStateEnum.Active,
        );
        this._logger.debug(`getActiveApps: appIDs: ${activeApps}`);
        return activeApps;
    };

    public getAppsByState = (state: AppStateEnum): string[] => {
        try {
            this._logger.debug('getAppsByState start');
            const selectedApps = Object.keys(this._appState).filter(
                (key) => this._appState[key].state === state,
            );
            const sortedSelectedApps = selectedApps.sort(
                (appID1, appID2) =>
                    this._appState[appID2].applicationLayerLevel -
                    this._appState[appID1].applicationLayerLevel,
            );
            this._logger.debug(`getAppsByState: appIDs: ${sortedSelectedApps?.join(', ')}`);
            return sortedSelectedApps;
        } catch (err) {
            this._logger.error('getAppsByState failed', err);
        }
    };

    public getOpenedApps = (): string[] => {
        const openedApps = Object.keys(this._appState).filter(
            (key) => this._appState[key].state !== AppStateEnum.Closed,
        );
        const reverseSortedOpenedApps = openedApps.sort(
            (appID1, appID2) =>
                this._appState[appID2].applicationLayerLevel -
                this._appState[appID1].applicationLayerLevel,
        );
        this._logger.debug(`getOpenedApps: appIDs: ${reverseSortedOpenedApps?.join(', ')}`);
        return reverseSortedOpenedApps;
    };

    public setOpenedWidget = (appID: AppIdentifiers, launchInfo: IAppLaunchInfo) => {
        this._openedWidgets[appID] = launchInfo;
    };

    public getOpenedWidget = (appID: AppIdentifiers) => this._openedWidgets[appID];

    public getSeqCorrIdHandler = (
        businessCorrelationID: string,
    ): SequentialCorrelationIdHandler => {
        this._seqCorrIdHandlerDict[businessCorrelationID] =
            this._seqCorrIdHandlerDict[businessCorrelationID] ||
            new SequentialCorrelationIdHandler();

        return this._seqCorrIdHandlerDict[businessCorrelationID];
    };

    public removeOpenWidget = (appId: AppIdentifiers) => {
        delete this._openedWidgets[appId];
    };

    public removeSeqCorrIdHandler = (businessCorrelationId: string): void => {
        const openedWidgets = Object.keys(this._openedWidgets).filter(
            (key) => this._openedWidgets[key]?.businessCorrelationID === businessCorrelationId,
        );
        if (!openedWidgets.length) {
            delete this._seqCorrIdHandlerDict[businessCorrelationId];
        }
    };

    public getBusinessCorrelationId = (appId: AppIdentifiers): string => {
        return this._openedWidgets[appId]?.businessCorrelationID;
    };

    public setBottomBarVisibility = async () => {
        try {
            this._logger.debug('[_setBottomBarVisibility] start');
            const isNativeDevice = this._utils.findIfIsNative();
            if (isNativeDevice) {
                let isShowBottomBar = true;
                const activeApps = this.getAppsByState(AppStateEnum.Active);
                const minimizedApps = this.getAppsByState(AppStateEnum.Minimized);
                if (minimizedApps && minimizedApps.length > 0) {
                    isShowBottomBar = false;
                } else {
                    for (let activeAppId of activeApps) {
                        const appCatalog = await this._appCatalogPromise;
                        const { app } = await appCatalog.getAppCatalogData(activeAppId);
                        const { openingMode } = app;
                        if (
                            openingMode === OpeningMode.rightSideDrawer ||
                            openingMode === OpeningMode.fullScreenPopup
                        ) {
                            isShowBottomBar = false;
                            break;
                        }
                    }
                }
                await NativeUtils.showBottomBar(isShowBottomBar);
            }
        } catch (err) {
            this._logger.error('[_setBottomBarVisibility] failed', err);
        }
    };

    private getApplicationLayerLevel = (appID: string): number => {
        try {
            const applicationLayerLevel = this.getAppState(appID)?.applicationLayerLevel;
            if (!applicationLayerLevel) {
                this._logger.debug(`getApplicationLayerLevel had no level, reset it to 1`);
                return 1;
            }
            return applicationLayerLevel;
        } catch (err) {
            this._logger.error('getApplicationLayerLevel failed', err);
        }
    };
}
