import Container from 'typedi';
import {
    FeatureAvailabilityToken,
    GTMDataLayerToken,
    LibraryManagerToken,
    PreloaderToken,
    UCFData,
    UserContextToken,
    UserDataStoreDeferredObjectToken,
} from '../../../../../injection-tokens';
import { GTMDataLayerName } from '../../../../../models/enums/Consts';
import { FeatureAvailability } from '../../../../feature/feature-availability/feature-availability';
import { UserContext } from '../../../../user-context';
import libraryManager from 'library-manager';

export const setClientServices = () => {
    try {
        Container.set(GTMDataLayerToken, window[GTMDataLayerName]); // todo: is it required all the times or just when the maintenance is enabled?
        Container.set(UCFData, unifiedClientDataProvider);
        Container.set(UserDataStoreDeferredObjectToken, userDataStoreDO);
        Container.set(UserContextToken, new UserContext());
        Container.set(PreloaderToken, preloader);
        Container.set(FeatureAvailabilityToken, new FeatureAvailability());
        Container.set(LibraryManagerToken, libraryManager);
    } catch (err) {
        console.error('setClientServices failed');
    }
};
