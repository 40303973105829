import { Container, Service } from 'typedi';
import { LoggerProvider } from '../logger';
import { WindowToken } from '../../injection-tokens';
import {
    LoadingSpeed,
    ClassesOfElements,
    IdsOfElements,
    UnifiedTheme,
    WidgetTheme,
    SDKType,
    MarketingBrand,
} from '../../models/enums/Consts';
import { UserContextToken } from '../../injection-tokens';
import { IUserContext } from '../user-context/user-context-interface';
import isFastNet from 'isfastnet';
import { v4 as uuid } from 'uuid';
import DeferredObject from '../../../Modules/Utils/DeferredObject';
import {
    LocalSimpleStoreService,
    SessionSimpleStoreService,
    WindowSimpleStoreService,
} from '../storage/implementations/simple-store';
import { UrlUtils } from './urlUtils';
import { ClientThemeName, ILogger, UserMode } from '@sparkware/uc-sdk-core';
import PageContextManager from 'page-context-manager';
import { StorageItemEnum } from '../../models/enums/storage-enums';

@Service()
export class Utils {
    private readonly _logger: ILogger;
    private readonly _window: Window;
    private readonly _userContext: IUserContext;
    private readonly _bandwidthPromise: Promise<IFastNet>;
    private readonly _localSimpleStoreService: LocalSimpleStoreService;
    private readonly _sessionSimpleStoreService: SessionSimpleStoreService;
    private readonly _urlUtils: UrlUtils;
    private readonly _windowSimpleStoreService: WindowSimpleStoreService;

    public constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('Utils');
        this._window = Container.get(WindowToken);
        this._userContext = Container.get(UserContextToken);
        this._urlUtils = Container.get(UrlUtils);
        this._localSimpleStoreService = Container.get(LocalSimpleStoreService);
        this._sessionSimpleStoreService = Container.get(SessionSimpleStoreService);
        this._windowSimpleStoreService = Container.get(WindowSimpleStoreService);

        if (!this.isNativeSDK()) {
            this._bandwidthPromise = this._evaluateBandwidth();
        }
    }

    public getLoadingSpeed = async () => {
        const bandwidth = await this.GetBandwidth();
        if (!bandwidth) return LoadingSpeed.SLOW;

        const averageLatency = bandwidth.averageLatency as number;

        if (averageLatency < 200) {
            return LoadingSpeed.SUPER_FAST;
        }

        if (averageLatency < 400) {
            return LoadingSpeed.FAST;
        }

        if (averageLatency < 1000) {
            return LoadingSpeed.MEDIUM;
        }

        return LoadingSpeed.SLOW;
    };

    public getClientProvider = () => {
        const { name } = PageContextManager.getClientProviderData();

        return name;
    };

    public getCorrelationId() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    public createContainerId(appID: string): string {
        return 'Container_' + appID + '_View';
    }

    public ShowElementWithId(elementId: string) {
        this._logger.log('ShowElementWithId: attempt: ' + elementId);

        let element = document.getElementById(elementId);
        if (element) {
            if (element.dataset.displayValue) {
                element.style.display = element.dataset.displayValue;
                element.removeAttribute('data-display-value');
            } else {
                element.style.display = 'block';
            }
            this._logger.log('ShowElementWithId: finish: ' + elementId);
        }
    }

    public HideElementWithId(elementId: string) {
        this._logger.log('HideElementWithId - attempt: ' + elementId);

        let element = document.getElementById(elementId);
        if (element) {
            if (element.style.display && element.style.display !== 'none') {
                element.setAttribute('data-display-value', element.style.display);
            }

            element.style.display = 'none';
            this._logger.log('HideElementWithId - finish: ' + elementId);
        }
    }

    public MergeElements(
        source: HTMLElement,
        target: HTMLElement,
        sourceId: string,
        targetId: string,
        displayStyle: string,
    ) {
        if (source && target) {
            source.setAttribute('id', target.id);
            source.style.display = displayStyle;

            target.parentElement.insertBefore(source, target);

            target.remove();
        } else this._logger.error(`${sourceId} or ${targetId} not found in document`);
    }

    public generateCorrelationID(): string {
        return uuid();
    }

    public isSlowConnection = () => {
        return preloader?.isSlowConnection() || false;
    };

    public getUserMode(): UserMode {
        return this._userContext.IsAuthenticated ? UserMode.Authenticated : UserMode.Anonymous;
    }

    public ClearElementWithId(elementId: string) {
        this._logger.log('ClearElementWithId: attempt: ' + elementId);

        let element = document.getElementById(elementId);
        if (element) {
            element.innerHTML = '';
        }
    }

    public EvaluateScripts = (html: string): string => {
        if (!html) {
            return html;
        }

        if (
            0 <=
            html.indexOf(
                '<!--WE NEED THIS COMMENT IN evaluateScripts FUNCTION TO PREVENT RELOADING THE APP TWICE-->',
            )
        ) {
            return html;
        }

        let scriptRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;

        html = html.replace(/<!--[\s\S]*?-->/g, '');
        let match = scriptRegex.exec(html);

        while (match) {
            try {
                this.EvaluateScriptsWithExternalFile(match[0]);
                match = scriptRegex.exec(html);
            } catch (err) {
                this._logger.error('Failed to load script!', match, err);
            }
        }

        return html;
    };

    public EvaluateScriptsWithExternalFile = (html: string) => {
        try {
            const fakeDiv = document.createElement('div');
            fakeDiv.innerHTML = html.trim();

            const scriptElements = fakeDiv.getElementsByTagName('script');
            const elementsLength = scriptElements ? scriptElements.length : 0;

            for (let index = 0; index < elementsLength; index++) {
                const script = document.createElement('script');

                if (scriptElements[index]['src']) {
                    script.src = scriptElements[index]['src'];
                    script.setAttribute('data-ot-ignore', '');
                } else {
                    //Add try catch to B2C scripts
                    const tryStart = 'try{';
                    const tryEnd =
                        "}catch(e){console.error('B2C ERROR In EXTERNAL SCRIPT, ERROR MESSAGE: ', e)}";
                    script.innerHTML = tryStart + scriptElements[index]['innerHTML'] + tryEnd;
                }

                document.head.appendChild(script).parentNode?.removeChild(script);
            }
        } catch (err) {
            this._logger.error(
                'Error in evaluateScriptsWithExternalFile function, err message:',
                err,
            );
        }
    };

    public GetBandwidth = async (): Promise<IFastNet> => this._bandwidthPromise;

    public PromiseTimeout(ms: number, promise: Promise<unknown>): Promise<void> {
        return new Promise((resolve, reject) => {
            let timeout: Promise<void> = new Promise((resolve) => {
                let id = setTimeout(() => {
                    clearTimeout(id);
                    resolve();
                }, ms);
            });

            return Promise.all([timeout, promise])
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    }

    public findIfIsNative() {
        const isAndroidNative = typeof window['WrapperInterface'] === 'object';

        if (isAndroidNative) return true;

        const isiOSNative =
            typeof window['webkit'] === 'object' &&
            typeof window['webkit'].messageHandlers.callbackHandler === 'object';
        if (isiOSNative) return true;

        const nativeUXVersion = this._urlUtils.getNativeUXVersion();
        if (nativeUXVersion > 0) return true;

        const nativeDataStore = this._sessionSimpleStoreService.get('NativeDataStore');

        return nativeDataStore !== undefined && nativeDataStore !== null;
    }

    public isPrerenderRequest = () => {
        return navigator.userAgent.toLowerCase().indexOf('prerender') !== -1;
    };

    public async GetGAClientIdAsync(): Promise<string> {
        let timeoutId = null;
        const timeoutPromise = new Promise<string>((resolve) => {
            timeoutId = setTimeout(() => {
                resolve(undefined);
            }, 1000);
        });
        const getGAClientIdPromise = new Promise<string>((resolve) => {
            if (this._window['ga'] && (this._window['ga'] as any).loaded) {
                this._window['ga']((tracker: { get: (arg0: string) => any }) => {
                    const clientId = tracker?.get('clientId');
                    resolve(clientId);
                });
            } else resolve(undefined);
        });
        const _clearTimeout = () => {
            if (timeoutId != null) clearTimeout(timeoutId);
        };
        return Promise.race([timeoutPromise, getGAClientIdPromise])
            .then((clientId) => {
                _clearTimeout();
                return clientId;
            })
            .catch(() => {
                _clearTimeout();
                return undefined;
            });
    }

    public ScrollToTop = (): void => {
        this._window.scrollTo(0, 1);
        document.getElementById(IdsOfElements.UcContentContainer)?.scrollTo(0, 1);
        document.querySelector(`.${ClassesOfElements.UcContentArea}`)?.scrollTo(0, 1);
        document.getElementById(IdsOfElements.UcContainer)?.scrollIntoView();
    };

    public GetGALinkerParam(): string {
        let linkerParam: string;

        if (this._window['ga']) {
            const tempGA = this._window['ga'] as any;

            if (tempGA.loaded) {
                tempGA(function () {
                    const trackers = tempGA.getAll();
                    linkerParam = trackers.length ? trackers[0].get('linkerParam') : undefined;
                });
            }
        }

        return linkerParam;
    }

    //determines and returns which sCut version needs to be used
    public GetSCutObject(): any {
        if (typeof this._window['sCut'] === 'object') {
            return this._window['sCut'];
        }

        if (typeof this._window['sCut2'] === 'object') {
            return this._window['sCut2'];
        }

        this._logger.error('sCut and sCut2 are not available as objects in page');
        return;
    }

    public async GetSCutTestData(): Promise<string> {
        const onSCutInitialized = this._window['onsCutInitialized'];
        const sCut = this.GetSCutObject();
        const sCutDO = new DeferredObject<string>();

        if (typeof onSCutInitialized === 'function') {
            onSCutInitialized(() => {
                sCutDO.resolve(sCut?.get('testdata') ?? '');
            });
        }

        return sCutDO.promise;
    }

    public TryParseJSON(input: string) {
        try {
            return JSON.parse(input);
        } catch (err) {
            return null;
        }
    }

    public isEmptyObject(obj: object): boolean {
        return Object.keys(obj).length === 0;
    }

    public stringReplaceAll = (str: string, find: string | RegExp, replace: string) => {
        if (!str) return str;
        if (typeof (str as any)?.replaceAll === 'function') {
            return (str as any)?.replaceAll(find, replace);
        }

        return str.replace(new RegExp(find, 'g'), replace);
    };

    public openWindow = (...params: string[]) => this._window.open(...params);

    public getHighestNumber = (number1?: number, number2?: number) => {
        if (number1 && number2) return number1 >= number2 ? number1 : number2;
        else return 0;
    };

    public compareVersions = (version1: string, version2: string) => {
        const v1Parts = version1.split('.').map(Number);
        const v2Parts = version2.split('.').map(Number);
        const maxLength: number = Math.max(v1Parts.length, v2Parts.length);

        for (let i = 0; i < maxLength; i++) {
            const num1: number = i < v1Parts.length ? v1Parts[i] : 0;
            const num2: number = i < v2Parts.length ? v2Parts[i] : 0;

            if (num1 > num2) return 1;
            if (num1 < num2) return -1;
        }

        return 0;
    };

    public isBossMode = (): boolean => {
        return this._localSimpleStoreService.get('IsBossMode') === 'true';
    };

    public isNativeSDK = (): boolean => {
        const currentSDKType = PageContextManager.getSiteData().sdkType;
        return [SDKType.AppWrapper, SDKType.NativeSDK].some(
            (sdktype) => currentSDKType === sdktype,
        );
    };
    public isAppWrapper = () => PageContextManager.getSiteData().sdkType === SDKType.AppWrapper;

    public getWidgetTheme = (): ClientThemeName => {
        if (this.isNativeSDK()) {
            return this._windowSimpleStoreService.get(
                StorageItemEnum.ClientThemeName,
            ) as ClientThemeName;
        }

        return (
            WidgetTheme[PageContextManager.getThemeData()?.currentName] ||
            WidgetTheme[UnifiedTheme.Default]
        );
    };

    public getMarketingBrandIdByWidgetTheme = () => {
        const widgetTheme = this.getWidgetTheme();
        switch (widgetTheme) {
            case WidgetTheme[UnifiedTheme.Poker]:
            case WidgetTheme[UnifiedTheme.Default]: {
                return MarketingBrand.Triple8;
            }
            case WidgetTheme[UnifiedTheme.MrGreen]: {
                return MarketingBrand.MrGreen;
            }
            case WidgetTheme[UnifiedTheme.SportsIllustrated]: {
                return MarketingBrand.SportsIllustrated;
            }

            default: {
                return MarketingBrand.Triple8;
            }
        }
    };

    public getAnonymousPlayerID = (): string => {
        return this._localSimpleStoreService.get('anonymousPlayerID');
    };

    private _evaluateBandwidth = (): Promise<IFastNet> => {
        const resourcesURL = PageContextManager.getSiteData().resourcesUrl;
        return new Promise((resolve) => {
            isFastNet(
                (data: IFastNet) => {
                    this._logger.log(
                        `EvaluateBandwidth: isFast: ${data?.isFast}, averageLatency: ${data?.averageLatency}, threshold: ${data?.threshold}, latencyR: ${data?.latencyReadings}`,
                    );

                    resolve(data);
                },
                {
                    timesToTest: 3, // optional, number of times to load the image default is 5
                    threshold: 300, // optional, threshold in ms after which internet speed is considered slow
                    image: `${resourcesURL}/images/bandwidth.gif`, //  optional, url of the tiny image to load, keep this on a CDN
                    allowEarlyExit: true, // optional, if the first request takes greater than threshold*3 ms then the function exits with false
                    verbose: true, // optional, if set, it returns an object with all calculated latency data. Overrides allowEarlyExit option (See Example "With verbose option" for usage)
                },
            );
        });
    };
}

interface IFastNet {
    isFast: Boolean;
    averageLatency: number;
    threshold: number;
    latencyReadings: [number];
}
