import { WindowToken } from '../../injection-tokens';
import Container, { Service } from 'typedi';
import DocumentHelper from '../../../Modules/Utils/DocumentHelper';
import { LoggerProvider } from '../logger';
import PageContextManager from 'page-context-manager';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class SmartBannerService {
    private readonly _logger: ILogger;
    private readonly _window: Window;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('SmartBannerService');
        this._window = Container.get(WindowToken);

        this._logger.log('Initialise smart banner service');
    }

    public addSmartBannerFeature() {
        DocumentHelper.onDomReady(() => {
            this._appendSmartBannerScript();
        });
    }

    private _appendSmartBannerScript = () => {
        const { key } = PageContextManager.getSmartBannerData();

        const smartBannerScript: string = `!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
            (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
            t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
            o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),
            p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "${key}"}})
            AF('banners', 'showBanner')`;

        const script = document.createElement('script');
        script.setAttribute('data-ot-ignore', '');
        script.innerHTML = smartBannerScript;

        this._window.document.head.appendChild(script).parentNode?.removeChild(script);
    };
}
