import { Container, Service } from 'typedi';
import { LoggerProvider } from '../logger';
import { ILogger, IOpenNativeAppData } from '@sparkware/uc-sdk-core';
import { ClientProvider, IdsOfElements } from '../../models/enums/Consts';
import { IAuthenticationFlowFactory, IAuthenticationFlowRunner } from '../authentication/models';
import { AuthenticationFlowType } from '../authentication/enums';
import { BiometricsUtils } from '../biometrics/biometrics.utils';
import { IMyAccount } from './models';
import { NativeUCAppType } from './enums/consts';
import { NativeUtils } from '../native/native.utils';
import { ClientsFrameworkOpenServiceProductService } from '../external/clients-framework';
import { Utils } from '../utils';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import { SessionSimpleStoreService } from '../storage/implementations/simple-store';
import { ILoginOptions } from '../authentication/models';
import { Registration } from '../registration/registration';
import PageContextManager from 'page-context-manager';
import { AuthenticationUtils } from '../authentication/utils/authentication.utils';
import { LoaderManager } from '../../loaders/LoaderManager';
import { PendingService } from '../pending/pending.service';

@Service()
export class PlayerService {
    private _manualLoginFlow?: IAuthenticationFlowRunner;
    private readonly _biometricsUtils: BiometricsUtils;
    private readonly _cfOpenServiceProductService: ClientsFrameworkOpenServiceProductService;
    private readonly _logger: ILogger;
    private readonly _sessionSimpleStoreService: SessionSimpleStoreService;
    private readonly _utils: Utils;
    protected readonly _registration: Registration;
    private readonly _authenticationUtils: AuthenticationUtils;
    private readonly _pendingService: PendingService;

    private get _authenticationFlowFactoryPromise(): Promise<IAuthenticationFlowFactory> {
        return LoaderManager.Instance.AuthenticationFlowFactoryLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('PlayerService');
        this._biometricsUtils = Container.get(BiometricsUtils);

        this._sessionSimpleStoreService = Container.get(SessionSimpleStoreService);
        this._cfOpenServiceProductService = Container.get(
            ClientsFrameworkOpenServiceProductService,
        );
        this._utils = Container.get(Utils);
        this._registration = Container.get(Registration);
        this._authenticationUtils = Container.get(AuthenticationUtils);
        this._pendingService = Container.get(PendingService);
    }

    async OpenMyAccount(myAccountModel?: IMyAccount) {
        let specificNavigationObj: any = {
            CallBack: { Method: 'POSTMessage', Target: location.origin },
        };

        if (myAccountModel?.tabId) specificNavigationObj.Deeplink = { Id: myAccountModel.tabId };

        if (myAccountModel?.showInAppCloseButton) specificNavigationObj.ShowCloseButton = true;

        specificNavigationObj.HideHeader = myAccountModel?.hideHeader || false;
        specificNavigationObj.HideMenu = myAccountModel?.hideMenu || false;

        const clientProvider = PageContextManager.getClientProviderData().name;
        if (clientProvider === ClientProvider.Spectate)
            specificNavigationObj.OddsFormat = this._sessionSimpleStoreService.get(
                StorageItemEnum.OddsFormat,
            );

        const { isMobile } = PageContextManager.getDeviceData();
        if (isMobile) {
            const isNative = this._utils.findIfIsNative();
            if (isNative) {
                const { isBiometricsNewFlowEnabled } =
                    await this._biometricsUtils.GetBiometricsFlowSettings();

                if (!isBiometricsNewFlowEnabled) {
                    specificNavigationObj.DisplayTouchID = true;

                    if (!myAccountModel?.tabId)
                        specificNavigationObj.ExternalLink = {
                            ActionId: 27,
                            UrlParameterName: 'Url',
                        };
                }
            } else {
                const isOnTouchIdSettings =
                    window.location.pathname.indexOf('Touchidsettings') >= 0;
                if (isOnTouchIdSettings) {
                    if (myAccountModel?.tabId) delete specificNavigationObj.Deeplink;
                } else {
                    specificNavigationObj.ViewPort = { Scale: '0.5' };
                }
            }

            const specificNavigation = encodeURIComponent(JSON.stringify(specificNavigationObj));

            this._cfOpenServiceProductService.clientAutoLogin(
                null,
                10,
                specificNavigation,
                '',
                '',
                '',
                100,
                '',
                true,
                false,
            );

            const ucContainer = document.getElementById(IdsOfElements.UcContainer);
            ucContainer?.style.setProperty('position', 'fixed');
        } else {
            const specificNavigation = encodeURIComponent(JSON.stringify(specificNavigationObj));

            this._cfOpenServiceProductService.clientAutoLogin(
                null,
                10,
                specificNavigation,
                940,
                658,
                myAccountModel?.onClose,
                '',
                '',
                false,
                !myAccountModel?.showInAppCloseButton,
            );

            setTimeout(function () {
                const scrollContainer = document.getElementById(IdsOfElements.UcContentContainer);
                scrollContainer?.classList.add('noScroll');
            }, 0);
        }
    }

    OpenNRS() {
        if (this._authenticationUtils.getAuthorizationData() == undefined)
            this._registration.openRegistration();
    }

    OpenBetFailed = async () => {
        await this._pendingService.showPending();
    };

    public openMyAccountPlayersLounge() {
        const myAccountModel: IMyAccount = { tabId: 16 };
        this.OpenMyAccount(myAccountModel);
    }

    public OpenNativeApp = (openNativeAppInput: IOpenNativeAppData): void => {
        let nativeSmartLink = null;

        switch (openNativeAppInput?.Type) {
            case NativeUCAppType.Sport888: {
                nativeSmartLink = PageContextManager.getNativeData()?.sportSmartLink;
                break;
            }
        }

        if (nativeSmartLink) NativeUtils.openExternalUrl(nativeSmartLink);
    };

    async openLogin(options: ILoginOptions = {}) {
        const authenticationFlowFactory = await this._authenticationFlowFactoryPromise;
        this._manualLoginFlow = await authenticationFlowFactory.create(
            AuthenticationFlowType.Regular,
        );
        await this._manualLoginFlow.execute(options);
    }

    openRegister = async ({ onClose }: { onClose: Function } = { onClose: null }) => {
        await this._registration.openRegistration();
    };
}
