import { ITracking } from './interfaces/ITracking';
import { ITrackingProvider } from '.';
import { LoggerProvider } from '../../logger';
import Container, { Service } from 'typedi';
import { Metric } from 'web-vitals';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class WebVitalsTracking implements ITracking {
    private _providers: Array<ITrackingProvider>;
    private _logger: ILogger;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('WebVitalsTracking');
        this._providers = [];
    }

    addProvider = (provider: ITrackingProvider) => {
        if (!provider) return;

        this._logger.log('Registering new web vitals tracking provider');

        this._providers.push(provider);
    };

    track = (webVitalsData: Metric) => {
        if (!this._providers || !this._providers.length) {
            this._logger.log(`No web vitals tracking providers set`);
            return;
        }

        for (let provider of this._providers) provider.track(webVitalsData);
    };
}
