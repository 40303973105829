import Container, { Service } from 'typedi';
import { LoggerProvider } from '../logger';
import { Metric, onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals/attribution';

import { ITracking } from './models/interfaces/ITracking';
import { WebVitalsTracking } from './models/web-vitals-tracking';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class WebVitals {
    private _logger: ILogger;

    private readonly _webVitalsTracking: ITracking;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('WebVitals');
        this._webVitalsTracking = Container.get(WebVitalsTracking);
    }

    public registerHandlers(): void {
        onCLS(this._sendReport);
        onFCP(this._sendReport);
        onFID(this._sendReport);
        onTTFB(this._sendReport);
        onLCP(this._sendReport);
        onINP(this._sendReport);
    }

    private _sendReport = (metric: Metric) => {
        this._logger.log(`_sendReport: ${JSON.stringify(metric, null, 2)}`);
        this._webVitalsTracking.track(metric);
    };
}
