import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { MessageBroker, IGeolocationChannel, ILogger } from '@sparkware/uc-sdk-core';
import { IGeolocation } from '../../geolocation/models/IGeolocation';
import { LoaderManager } from '../../../loaders/LoaderManager';

@Service()
export class GeolocationChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _geolocationChannel: IGeolocationChannel;

    private get _geolocationPromise(): Promise<IGeolocation> {
        return LoaderManager.Instance.GeolocationLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('GeolocationChannelSubscriber');
        this._geolocationChannel = MessageBroker.getInstance().geolocation;
        this._geolocationChannel.topics.userInitiatedRetry.subscribe(
            this.onUserInitiatedRetry.bind(this),
        );
    }

    private async onUserInitiatedRetry() {
        const geolocation = await this._geolocationPromise;
        geolocation?.retryFlowUser();
    }
}
