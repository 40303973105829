import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import {
    ILogger,
    IPaymentActionData,
    IPaymentChannel,
    MessageBroker,
} from '@sparkware/uc-sdk-core';
import { Payments } from '../../payments';

@Service()
export class PaymentsChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _paymentsChannel: IPaymentChannel;
    private readonly _payments: Payments;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('PaymentsChannelSubscriber');
        this._paymentsChannel = MessageBroker.getInstance().payments;
        this._payments = Container.get(Payments);
        this._paymentsChannel.topics.CTA_Cashier_Open.subscribe(this.onCTACashierOpen.bind(this));
        this._paymentsChannel.topics.CTA_Quick_Deposit.subscribe(this.onCTAQuickDeposit.bind(this));
    }

    private async onCTACashierOpen(data: IPaymentActionData) {
        await this._payments.onPaymentOpen(data);
    }

    private async onCTAQuickDeposit(data: IPaymentActionData) {
        this._payments.onQuickDeposit(data);
    }
}
