import { Container, Service } from 'typedi';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
    Level,
} from '@unified-client/event-formatter';
import { GeolocationElasticEvents, GeolocationReason } from '../enums';
import {
    IGeolocationClickStreamEventData,
    IGeolocationEventSender,
    IGeolocationRequest,
    IGeolocationResult,
    IInformLocationRequest,
} from '../models';
import { LoggerProvider } from '../../logger';

import { ClickStreamTrackingProvider } from '../../tracking';
import { GeolocationMessagesUtils } from './geolocation.messages.utils';
import { StorageItemEnum } from '../../../models/enums/storage-enums';
import StringUtils from '../../../../Modules/Utils/StringUtils';
import { LocalStoreService } from '../../storage/implementations/store';
import { INativeService } from '../../native/models/INativeService';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { IBetFailedPayload, ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class GeolocationLogging {
    private _correlationID: string;

    private readonly _logger: ILogger;
    private readonly _clickStreamTrackingProvider: ClickStreamTrackingProvider;
    private readonly _eventFormatterBuilder: EventFormatterBuilder;
    private readonly _localStoreService: LocalStoreService;

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('geolocation');
        const efBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder = efBuilderFactory.createEventFormatterBuilder('geolocation');
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
        this._localStoreService = Container.get(LocalStoreService);
    }

    public GetElasticEventSender(correlationId: string): IGeolocationEventSender {
        this._correlationID = correlationId;

        return {
            sendInitGeoReceived: async (fname: string, geolocationDetails: any): Promise<void> => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);
                const nativeService = await this._nativeServicePromise;

                const skippedDueToAppInBackground: boolean =
                    nativeService?.isAppInBackground ?? false;

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_InitGeo_Received,
                    geolocationDetails,
                    skippedDueToAppInBackground,
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendUpdateRestriction: (fname: string, message: any, duration: number): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);
                const isRestricted = GeolocationMessagesUtils.isRestricted(message);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Update_Restriction,
                    description: `User is ${isRestricted ? 'restricted' : 'permitted'}`,
                    troubleshooterMessages: StringUtils.toString(message?.TroubleshooterMessages),
                    troubleshooterRetry: message?.TroubleshooterRetry ?? false,
                    ...(duration && { duration }),
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendBackgroundRestriction: (fname: string): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Background_Restriction,
                    description: 'A restriction was applied while session been in the background',
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendRetryStart: (
                fname: string,
                geolocationReason: GeolocationReason,
                initGeoRetryEnabled: boolean,
            ): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Retry_Start,
                    geolocationReason,
                    description: `A retry flow was started, initGeoRetryEnabled: ${initGeoRetryEnabled}`,
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendRetryApiResponse: (
                fname: string,
                response: any,
                geolocationReason: GeolocationReason,
            ): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Retry_API_Response,
                    geolocationReason,
                    description: 'A restriction retry response received',
                    response,
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendDataMissing: (fname: string): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Data_Missing,
                    description: 'error during getting geolocation data from localstorage',
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendLoginGeolocationData: (fname: string): void => {
                const {
                    CID,
                    GeoComplyLicenseKey,
                    GeoDetectionTransactionId,
                    PhoneNumber,
                    EnableGeolocation,
                } = this._localStoreService.get(StorageItemEnum.GeolocationData) || {};

                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Login_Geolocation_Data,
                    geolocationDetails: {
                        CID,
                        GeoComplyLicenseKey,
                        GeoDetectionTransactionId,
                        PhoneNumber,
                        EnableGeolocation,
                    },
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendAvailableStart: (fname: string): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Available_Start,
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendAvailableEnd: (
                fname: string,
                response: IGeolocationResult,
                duration: number,
            ): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Available_End,
                    response,
                    duration,
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendInstallationEnd: (fname: string, duration: number): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Installation_End,
                    duration,
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendInstallationStart: (fname: string): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Installation_Start,
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendGeolocationStart: (fname: string, request: IGeolocationRequest): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Geolocation_Start,
                    geolocationDetails: request,
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendGeolocationEnd: (
                fname: string,
                response: IGeolocationResult,
                duration: number,
            ): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Geolocation_End,
                    response,
                    duration,
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendPluginTimeout: (fname: string, request: IGeolocationRequest): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Plugin_Timeout,
                    geolocationDetails: request,
                };

                this.sendElasticEvent(formatter, eventData, Level.warning);
            },
            sendPluginGetlocationError: (fname: string, request: IGeolocationRequest): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Plugin_Getlocation_Error,
                    geolocationDetails: request,
                };

                this.sendElasticEvent(formatter, eventData, Level.warning);
            },
            sendInformLocationRequest: (fname: string, request: IInformLocationRequest): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_InformLocation_Request,
                    request,
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendInformLocation_Timeout: (
                fname: string,
                request: IInformLocationRequest,
                geolocationReason: GeolocationReason,
            ): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_InformLocation_Timeout,
                    geolocationReason,
                    request,
                };

                this.sendElasticEvent(formatter, eventData, Level.warning);
            },
            sendInformLocation_Success_Response: (fname: string, response: any): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_InformLocation_Success_Response,
                    response,
                };

                this.sendElasticEvent(formatter, eventData);
            },
            sendInformLocation_Fail_Response: (fname: string, message: any): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_InformLocation_Fail_Response,
                    response: message,
                };

                this.sendElasticEvent(formatter, eventData);
            },

            sendStatusUpdate: (fname: string, status: string): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Status_Update,
                    description: `Geolocation status message publish with status: ${status}`,
                };

                this.sendElasticEvent(formatter, eventData);
            },

            sendDialogShow: (fname: string, dialogType: string, options: string): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Dialog_Show,
                    description: dialogType,
                    geolocationDetails: options,
                };

                this.sendElasticEvent(formatter, eventData);
            },

            sendGCAdapterLoader: (fname: string, gcAdapterName: string): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Adapter_Loader,
                    description: `The GC Adapter ${gcAdapterName} was loaded `,
                };

                this.sendElasticEvent(formatter, eventData);
            },

            sendInitGeolocationFlow: (fname: string, request: IGeolocationRequest): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Init_Flow,
                    request,
                };

                this.sendElasticEvent(formatter, eventData);
            },

            sendIsGeoLocationAvailable: (fname: string, response: IGeolocationResult): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Geolocation_Avialable,
                    response,
                };

                this.sendElasticEvent(formatter, eventData);
            },

            sendBetFailed: (fname: string, payload: IBetFailedPayload): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Bet_Failed,
                    description: `Bet failed error code: ${payload.errorCode} `,
                };

                this.sendElasticEvent(formatter, eventData);
            },

            sendDesktopPluginNotInstalled: (fname: string, request: IGeolocationRequest): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Desktop_Plugin_Not_Installed,
                    request,
                };

                this.sendElasticEvent(formatter, eventData);
            },

            sendHandleGeolocationResult: (fname: string, response: any): void => {
                const formatter = this._eventFormatterBuilder.createFormatter(fname);

                const eventData: IGeolocationClickStreamEventData = {
                    event: GeolocationElasticEvents.GL_Handle_Result,
                    response,
                };

                this.sendElasticEvent(formatter, eventData);
            },
        };
    }

    public sendElasticEvent(formatter: any, eventData: any, level: Level = undefined): void {
        const correlationID = this._correlationID;

        const event = formatter.formatUCEvent(
            {
                message: eventData.event,
                ...(eventData.duration && { durationInMS: eventData.duration }),
            },
            { ...(correlationID && { correlationID }), ...(level && { level }) },
            { ...eventData },
        );

        this._clickStreamTrackingProvider.sendEventV2(event);
    }
}
