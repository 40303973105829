import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { IPlayer } from '../../player/models';
import { ClientIntegrationFacadeToken } from '../../../injection-tokens';
import { IClientIntegrationFacade } from '../../client-integration/interfaces/IClientIntegrationFacade';
import { ILoginOptions } from '../../authentication/models';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { ViewInjector } from '../../view-injector';
import { Wallet } from '../../wallet';
import { ILogger } from '@sparkware/uc-sdk-core';
import { ICMSContentLoader } from '../../view-injector/models/ICMSContentLoader';

@Service()
export class ClientIntegrationChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _clientIntegrationFacade: IClientIntegrationFacade;
    private readonly _viewInjector: ViewInjector;
    private readonly _wallet: Wallet;

    private get _playerPromise(): Promise<IPlayer> {
        return LoaderManager.Instance.PlayerLoader.Instance;
    }

    private get _cmsContentLoaderPromise(): Promise<ICMSContentLoader> {
        return LoaderManager.Instance.CMSContentLoaderLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger(
            'ClientIntegrationChannelSubscriber',
        );
        this._clientIntegrationFacade = Container.get(ClientIntegrationFacadeToken);
        this._viewInjector = Container.get(ViewInjector);
        this._wallet = Container.get(Wallet);
        this._clientIntegrationFacade.openLoginSubscribe(this.onClientOpenLogin.bind(this));
        this._clientIntegrationFacade.clientInitSuccessSubscribe(
            this.onClientInitSuccess.bind(this),
        );
        this._clientIntegrationFacade.betSuccessSubscribe(this.onBetSuccess.bind(this));
        this._clientIntegrationFacade.clientInitFailSubscribe(this.onClientInitFail.bind(this));
    }

    private async onClientOpenLogin(options: ILoginOptions = {}) {
        const player = await this._playerPromise;
        player?.onClientOpenLogin(options);
    }

    private async onClientInitSuccess() {
        this._viewInjector.OnClientInitSuccess();
        const cmsContentLoader = await this._cmsContentLoaderPromise;
        cmsContentLoader.OnClientInit(true);
    }

    private async onBetSuccess() {
        await this._wallet.onBetSuccess();
    }

    private async onClientInitFail() {
        const cmsContentLoader = await this._cmsContentLoaderPromise;
        cmsContentLoader.OnClientInit(false);
    }
}
