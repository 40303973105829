import { ClientIntegrationFacadeToken } from '../../injection-tokens';
import { Container } from 'typedi';
import { LoggerProvider } from '../logger';
import { PlatformLogoutReason, StaticPaths } from '../../models/enums/Consts';
import { RouterService } from '../router/router.service';
import { ViewInjector } from '../view-injector';
import { ClientsFrameworkLogoutService } from '../external/clients-framework';
import { IClientIntegrationFacade } from '../client-integration/interfaces/IClientIntegrationFacade';
import { BrandUtils } from '../../../Modules/Utils/BrandUtils';
import { IIdentity } from './models/IIdentity';
import { ILogger, ILoginSuccessData } from '@sparkware/uc-sdk-core';

export class Identity implements IIdentity {
    private readonly _clientIntegrationFacade: IClientIntegrationFacade;
    private readonly _logger: ILogger;
    private readonly _routerService: RouterService;
    private readonly _viewInjector: ViewInjector;
    private readonly _logoutService: ClientsFrameworkLogoutService;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('Identity');
        this._routerService = Container.get(RouterService);
        this._clientIntegrationFacade = Container.get(ClientIntegrationFacadeToken);
        this._viewInjector = Container.get(ViewInjector);
        this._logoutService = Container.get(ClientsFrameworkLogoutService);

        const { loginFailSubscribe } = this._clientIntegrationFacade;
        loginFailSubscribe(this._onUserAuthenticationFailed);
        this.handlePopStateEvents = this.handlePopStateEvents.bind(this);
    }

    public onLogout = (): void => {
        this._viewInjector.OnAuthenticationStatusChangeContentUpdate();
    };

    public onLoginSuccess = (data: ILoginSuccessData): void => {
        this._viewInjector.OnAuthenticationStatusChangeContentUpdate();
    };

    public handlePopStateEvents(event: PopStateEvent): void {
        if (BrandUtils.isPokerClient()) {
            this._routerService.NavigateTo(StaticPaths.PokerRoot);
        } else {
            this._routerService.NavigateTo(StaticPaths.Root);
        }
        window.removeEventListener('popstate', this.handlePopStateEvents);
        document.querySelector('.main-loader')?.remove();
    }

    private _onUserAuthenticationFailed = ({ error }): void => {
        this._logger.info('userAuthenticationFailed message received. error: ', error);
        let logoutReason = PlatformLogoutReason.LoginError;
        this._logoutService.doLogout(logoutReason);
        window.addEventListener('popstate', this.handlePopStateEvents);
    };
}
