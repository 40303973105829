import Container, { Service } from 'typedi';
import { parse } from 'url';
import { UCFData, WindowToken } from '../../injection-tokens';
import { StaticPaths } from '../../models/enums/Consts';
import { LoggerProvider } from '../logger';
import { IWildcard } from './models';
import { UrlUtils } from '../utils/urlUtils';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class RouterUtils {
    private _wildcardRegexList: Array<RegExp> = [];
    private _logger: ILogger;
    private _window: Window;
    private readonly _urlUtils: UrlUtils;

    public get URI(): string {
        return this._urlUtils.getURLWithoutHost(window.location.href);
    }

    constructor() {
        this._urlUtils = Container.get(UrlUtils);
        this._window = Container.get(WindowToken);
        this._logger = Container.get(LoggerProvider).getLogger('RouterUtils');
        this._setWildcardRegex();
    }

    public isClientNavigation(url: string): boolean {
        const parsedURL = parse(
            this._urlUtils.getRelativeURL(url, { removeLanguage: true, removeBaseUrl: true }),
        );

        if (!parsedURL) return false;

        const decodedUrl = decodeURIComponent(parsedURL.pathname);
        return (
            !!this._wildcardRegexList?.some((regex) => regex.test(decodedUrl)) ||
            decodedUrl === StaticPaths.Root ||
            this.isClientUrl(decodedUrl)
        );
    }

    public isHostedClientEnabledOnURL(url: string): boolean {
        return this.isClientNavigation(url);
    }

    public isSportClientEnabled(): boolean {
        return this.isClientNavigation(this._window.location.href);
    }
    //Checks if decodedUrl is found in configuration ClientUrls
    public isClientUrl(decodedUrl: string): boolean {
        const normalizedUrl = this._normalizeUrl(decodedUrl);
        const clientUrls = this._getClientUrls();

        return clientUrls.some((clientUrl) => clientUrl === normalizedUrl);
    }

    private _setWildcardRegex(): void {
        this._logger.log('Creating wildcard regex list...');
        const ucfData = Container.get(UCFData);
        const wildcards: IWildcard[] = ucfData?.navigationData?.WildcardUrls || [];

        wildcards.forEach((wildcard) => {
            try {
                const decodedUrl = decodeURIComponent(wildcard.Url);
                const wildcardRegex = RegExp(`^(${decodedUrl}\/[^]*|${decodedUrl})$`);

                this._wildcardRegexList.push(wildcardRegex);
            } catch (err) {
                this._logger.error(`setWildcardRegex failed for wildcardUrl: '${wildcard.Url}'`);
            }
        });
    }

    private _getClientUrls(): string[] {
        const ucfData = Container.get(UCFData);
        const clientUrls: string[] = ucfData?.navigationData?.ClientUrls || [];

        const normalizedUrls =
            clientUrls?.length > 0 ? clientUrls.map((url) => this._normalizeUrl(url)) : [];

        return normalizedUrls;
    }

    //normalize urls (add '/' ad the end if missing)
    private _normalizeUrl(url: string) {
        if (!url) return ''; // if empty
        return !url.endsWith('/') ? url + '/' : url;
    }
}
