import Container, { Service } from 'typedi';
import { SmartBannerService } from './smart.banner.service';
import { LoggerProvider } from '../logger';
import { FeatureAvailabilityToken, UserContextToken } from '../../injection-tokens';
import { IUserContext } from '../user-context/user-context-interface';
import { Features, SmartBannerNotRequiredFtdBrands } from '../../models/enums/Consts';
import DeferredObject from '../../../Modules/Utils/DeferredObject';
import { UserDataStoreDeferredObjectToken } from '../../injection-tokens';
import { Utils } from '../utils';
import PageContextManager from 'page-context-manager';
import { IFeatureAvailability } from '../feature/feature-availability/feature-availability-interface';
import { ISessionUserData } from '../session-manager/interfaces/ISessionUserData';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class SmartBannerController {
    private readonly _logger: ILogger;
    private readonly _smartBannerService: SmartBannerService;
    private readonly _userContext: IUserContext;
    private readonly _refreshUserStateDO: DeferredObject<ISessionUserData>;
    private readonly _utils: Utils;
    private readonly _featureAvailability: IFeatureAvailability;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('SmartBannerController');
        this._smartBannerService = Container.get(SmartBannerService);
        this._userContext = Container.get(UserContextToken);
        this._refreshUserStateDO = Container.get(UserDataStoreDeferredObjectToken);
        this._utils = Container.get(Utils);
        this._featureAvailability = Container.get(FeatureAvailabilityToken);
    }

    public registerSmartBannerService(): void {
        this._refreshUserStateDO.promise.then(() => {
            const isSmartBannerEnabled = this._isSmartBannerEnabled();
            if (isSmartBannerEnabled) {
                this._smartBannerService.addSmartBannerFeature();
            } else this._logger.log('Smart Banner is not enabled, not initialising');
        });
    }

    private _isSmartBannerEnabled(): boolean {
        const isMobile = PageContextManager.getDeviceData().isMobile;
        const { isFtd } = PageContextManager.getUserData();
        const smartBannerEnabled = this._featureAvailability.FeatureIsEnabled(
            Features.SMART_BANNER,
        );
        return (
            smartBannerEnabled &&
            ((this._userContext.IsAuthenticated && isFtd) || this._isSmartBannerNotRequiredFtd()) &&
            isMobile &&
            !this._utils.findIfIsNative()
        );
    }

    private _isSmartBannerNotRequiredFtd = (): boolean => {
        const { brandId } = PageContextManager.getBrandData();
        return !!SmartBannerNotRequiredFtdBrands.find((brand) => brand === brandId);
    };
}
