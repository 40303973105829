import Container, { Service } from 'typedi';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
    IEvent,
} from '@unified-client/event-formatter';
import { ILogger, ITopicEnvelope, ITrackingChannel, MessageBroker } from '@sparkware/uc-sdk-core';
import { LoggerProvider } from '../../logger';
import { ClickStreamTrackingProvider } from '../ClickStreamTrackingProvider';
import { ITracking } from './interfaces/ITracking';
import { ITrackingEvent } from './ITrackingEvent';
import { Tracking } from './tracking';
import { TrackingContext } from './trackingContext';
import { Utils } from '../../utils';
import { DataStoreDeferredObjectToken, UCFData, WindowToken } from '../../../injection-tokens';
import DeferredObject from '../../../../Modules/Utils/DeferredObject';
import PageContextManager from 'page-context-manager';
import { IAuthenticatedTrackingMetadata, ITrackingMetadata } from './interfaces';
import { EventType } from '@unified-client/event-formatter/dist/models/enums/EventType';
import { CONSTS } from '@unified-client/event-formatter/dist/consts';
import LogIndex from '../enums/LogIndex';

@Service()
export class ClientTracking {
    private readonly _defaultEventName = 'UC_GENERIC_EVENT';

    private readonly _logger: ILogger;
    private readonly _tracking: ITracking;
    private readonly _window: Window;
    private readonly _trackingContext: TrackingContext;
    private readonly _utils: Utils;
    private readonly _clientTrackingChannel: ITrackingChannel;
    private readonly _clickStreamTrackingProvider: ClickStreamTrackingProvider;
    private readonly _eventFormatterBuilder: EventFormatterBuilder;

    private readonly _dataStoreDO: DeferredObject<void>;

    public get channel(): ITrackingChannel {
        return this._clientTrackingChannel;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('ClientTracking');
        this._window = Container.get(WindowToken);
        this._tracking = Container.get(Tracking);
        this._clientTrackingChannel = MessageBroker.getInstance().tracking;
        this._trackingContext = Container.get(TrackingContext);
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
        this._utils = Container.get(Utils);
        this._dataStoreDO = Container.get(DataStoreDeferredObjectToken);

        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);

        this._eventFormatterBuilder =
            eventFormatterBuilderFactory.createEventFormatterBuilder('clientTracking');

        this._logger.log('Initialising Client Tracking module...');

        this._defaultEventName = 'UC_GENERIC_EVENT';
    }

    public sendMaintenanceEvent = () => {
        const toTrackEventData: any = {
            event: this._defaultEventName,
            category: 'SpectateMaintenance',
            subBrandName: this._trackingContext.SubBrandName,
            deviceType: this._trackingContext.DeviceType,
            action: 'view',
            label: 'SpectateMaintenancePage',
            value: '',
            custom: '',
        };

        this._tracking.track(toTrackEventData);
    };

    public addTrackJsMetaData = () => {
        //initial data
        this._setTrackingMetadata();

        //refreshed store data
        this._dataStoreDO.promise.then(() => {
            this._setTrackingMetadata();
        });
    };

    public addTrackingAuthMetadata() {
        const cid = PageContextManager.getUserData()?.cid;
        const loginSessionID = PageContextManager.getSessionData()?.globalSessionId;
        const metadata: IAuthenticatedTrackingMetadata = {
            cid,
            sessionId: loginSessionID,
        };

        this._addTrackJSAuthMetadata(metadata);
        this._addRequestMetricsAuthMetadata(metadata);
    }

    public onHandleClickEvents = (event: MouseEvent): void => {
        const toTrackEventData = this._getTrackingEvent(event);

        if (!toTrackEventData) return;

        this._tracking.track(toTrackEventData);
    };

    public onSendEvent = (inputData: any, envelope: ITopicEnvelope) => {
        //Check where is this being published from
        //Maybe send a deprecated event to kibana with the inputData
        const subComponentName =
            envelope.headers.publisher || '_registerTrackingSendEventSubscriber';
        const correlationID =
            envelope?.headers?.correlationID ?? this._utils.generateCorrelationID();
        const formatter = this._eventFormatterBuilder.createFormatter(subComponentName);
        const { eventTypeOverride, eventNameOverride } = inputData.event ?? {};
        let event: Readonly<IEvent<any, any>>;

        // if custom data is present, format it and call the method that allows overriding
        if (eventTypeOverride || eventNameOverride || inputData.target) {
            const eventTypeArray =
                typeof eventTypeOverride === 'string' ? [eventTypeOverride] : eventTypeOverride;
            const validEventTypes = eventTypeArray
                .map((type: EventType) =>
                    Object.values(EventType).find((eventType) => eventType === type),
                )
                .filter(Boolean);

            event = formatter.formatBusinessEvent(
                {
                    eventName: eventNameOverride || CONSTS.UC_EVENT_NAME,
                    eventType: validEventTypes.length > 0 ? validEventTypes : [EventType.Log],
                },
                {
                    correlationID,
                    targetOverride: inputData.target || LogIndex.UnifiedClient,
                },
                inputData,
            );
        } else {
            event = formatter.formatUCEvent(inputData.event, { correlationID }, inputData);
        }

        this._clickStreamTrackingProvider.sendEventV2(event);
    };

    public onSendAnalytics = (trackingInfo: any, envelope: ITopicEnvelope) => {
        if (
            !!trackingInfo &&
            !!(trackingInfo.dataCategory || trackingInfo.dataCustom?.category) &&
            !!trackingInfo.dataAction
        ) {
            const subComponentName =
                envelope.headers.publisher || '_registerTrackingSendAnalyticsSubscriber';
            const toTrackEventData: any = {
                event: trackingInfo.dataEvent || this._defaultEventName,
                category: trackingInfo.dataCategory,
                subBrandName: this._trackingContext.SubBrandName,
                deviceType: this._trackingContext.DeviceType,
                action: trackingInfo.dataAction,
                label: trackingInfo.dataLabel,
                value: trackingInfo.dataValue,
                custom: trackingInfo.dataCustom,
            };

            const correlationId = this._utils.getCorrelationId();

            this._tracking.track(toTrackEventData, correlationId, subComponentName);
        } else this._logger.log('Not trackable click channel event...');
    };

    public onLogoutSuccess() {
        this._removeTrackJSAuthMetadata();
        this._removeRequestMetricsAuthMetadata();
    }

    public onLoginSuccess = () => {
        this.addTrackingAuthMetadata();
    };

    private _getTrackingEvent = (event: MouseEvent): ITrackingEvent => {
        this._logger.log('Get tracking event data');

        const anchor: HTMLAnchorElement =
            typeof event.target['closest'] === 'function'
                ? event.target['closest']('a')
                : undefined;

        if (!anchor) {
            this._logger.log('No anchor click event...');
            return;
        }

        const dataAction = anchor.getAttribute('data-action');
        const dataCategory = anchor.getAttribute('data-category');
        if (!dataAction || !dataCategory) {
            this._logger.log('Not trackable click event...');
            return;
        }

        return {
            event: anchor.getAttribute('data-event') || this._defaultEventName,
            category: dataCategory,
            subBrandName: this._trackingContext.SubBrandName,
            deviceType: this._trackingContext.DeviceType,
            action: dataAction,
            label: anchor.getAttribute('data-label'),
            value: anchor.getAttribute('data-value'),
        };
    };

    private _setTrackingMetadata = () => {
        const { clientVersion, productPackage, isHybrid } = PageContextManager.getDeviceData();
        const { brandId, subBrandId } = PageContextManager.getBrandData();
        const { clientLangIso3 } = PageContextManager.getLocalizationData();
        const { country } = PageContextManager.getGlobalizationData();
        const { buildNumber, versionNumber } = PageContextManager.getReleaseData();
        const { systemId, serverName } = PageContextManager.getEnvironmentData();
        const ucfData = Container.get(UCFData);

        const metadata: ITrackingMetadata = {
            clientVersion: clientVersion,
            productPackage: productPackage,
            brandId: brandId,
            subBrandId: subBrandId,
            langIso3: clientLangIso3,
            country: country,
            isHybrid: isHybrid,
            deviceType: this._trackingContext.DeviceType,
            sessionCorrelationId: this._trackingContext.getUcSessionCorrelationId(),
            pageCorrelationId: ucfData?.pageCorrelationId,
            serverName: serverName,
            systemId: systemId,
            buildNumber: buildNumber,
            versionNumber: versionNumber,
        };

        this._setTrackJSMetadata(metadata);
        this._setRequestMetricsMetadata(metadata);
    };

    private _setTrackJSMetadata = (metadata: ITrackingMetadata) => {
        if (!this._window['TrackJS']) return;

        this._window['TrackJS'].addMetadata('clientVersion', metadata.clientVersion);
        this._window['TrackJS'].addMetadata('productPackage', metadata.productPackage);
        this._window['TrackJS'].addMetadata('brandId', metadata.brandId?.toString());
        this._window['TrackJS'].addMetadata('subBrandId', metadata.subBrandId?.toString());
        this._window['TrackJS'].addMetadata('langIso3', metadata.langIso3);
        this._window['TrackJS'].addMetadata('country', metadata.country);
        this._window['TrackJS'].addMetadata('isHybrid', metadata.isHybrid?.toString());
        this._window['TrackJS'].addMetadata('deviceType', metadata.deviceType);
        this._window['TrackJS'].addMetadata(
            'uc-session-correlation-ID',
            metadata.sessionCorrelationId,
        );
        this._window['TrackJS'].addMetadata('uc-page-correlation-ID', metadata.pageCorrelationId);
        this._window['TrackJS'].addMetadata('serverName', metadata.serverName);
        this._window['TrackJS'].addMetadata('systemID', metadata.systemId);
        this._window['TrackJS'].addMetadata('buildNumber', metadata.buildNumber);
        this._window['TrackJS'].addMetadata('versionNumber', metadata.versionNumber);
    };

    private _setRequestMetricsMetadata = (metadata: ITrackingMetadata) => {
        this._window['RM']?.addMetadata(metadata);
    };

    private _addTrackJSAuthMetadata = (metadata: IAuthenticatedTrackingMetadata) => {
        if (!this._window['TrackJS']) return;

        this._window['TrackJS'].addMetadata('CID', metadata.cid?.toString());
        this._window['TrackJS'].addMetadata('loginSessionID', metadata.sessionId?.toString());
    };

    private _addRequestMetricsAuthMetadata = (metadata: IAuthenticatedTrackingMetadata) => {
        this._window['RM']?.identify(`usr_${metadata.cid}_${metadata.sessionId}`, metadata);
    };

    private _removeTrackJSAuthMetadata = () => {
        if (this._window['TrackJS']) {
            this._window['TrackJS'].removeMetadata('CID');
            this._window['TrackJS'].removeMetadata('loginSessionID');
        }
    };

    private _removeRequestMetricsAuthMetadata = () => {
        this._window['RM']?.identify('unknown', {});
    };
}
