import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import {
    IAppClosedTopicPayload,
    IAppCloseIntentResultTopicPayload,
    IAppErrorTopicPayload,
    IAppInitFailedTopicPayload,
    IAppInitSucceededTopicPayload,
    IAppMinimizedTopicPayload,
    ILogger,
    ISessionChannel,
    MessageBroker,
} from '@sparkware/uc-sdk-core';
import { IMb2Cb } from '../../mb-2-cb/models/IMb2Cb';
import { ISessionManager } from '../../session-manager/interfaces/ISessionManager';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { IPreloaderManager } from '../../ui/preloader/models/interfaces/IPreloaderManager';
import { UrlUtils } from '../../utils/urlUtils';
import { IClientInitFailed } from '../../ui/preloader/models/interfaces/IClientInitFailed';

@Service()
export class SessionChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _sessionManagerChannel: ISessionChannel;
    private readonly _urlUtils: UrlUtils;

    private get _mb2CBPromise(): Promise<IMb2Cb> {
        return LoaderManager.Instance.Mb2CbLoader.Instance;
    }

    private get _sessionManagerPromise(): Promise<ISessionManager> {
        return LoaderManager.Instance.SessionManagerLoader.Instance;
    }

    private get _preloaderManagerPromise(): Promise<IPreloaderManager> {
        return LoaderManager.Instance.PreloaderManagerLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('SessionChannelSubscriber');
        this._urlUtils = Container.get(UrlUtils);
        this._sessionManagerChannel = MessageBroker.getInstance().session;
        this._sessionManagerChannel.topics.appClosed.subscribe(this.onClose.bind(this));
        this._sessionManagerChannel.topics.clientInitSucceeded.subscribe(
            this.onClientInitSucceeded.bind(this),
        );
        this._sessionManagerChannel.topics.clientInitFailed.subscribe(
            this.onClientInitFailed.bind(this),
        );
        if (!this._urlUtils.isAutologinRequest) {
            this._sessionManagerChannel.topics.inactivityTimeout.subscribe(
                this.onInactivityTimeout.bind(this),
            );
        }
        this._sessionManagerChannel.topics.heartBeat.subscribe(this.onHeartBeat.bind(this));
        this._sessionManagerChannel.topics.appInitSucceeded.subscribe(
            this.onInitSucceeded.bind(this),
        );
        this._sessionManagerChannel.topics.appInitFailed.subscribe(this.onInitFailed.bind(this));
        this._sessionManagerChannel.topics.appError.subscribe(this.onError.bind(this));
        this._sessionManagerChannel.topics.closeIntentResult.subscribe(
            this.closeIntentResult.bind(this),
        );
        this._sessionManagerChannel.topics.heartBeat.subscribe(this.onUserInteraction.bind(this));
        this._sessionManagerChannel.topics.appMinimized.subscribe(this.onAppMinimized.bind(this));
    }

    private async onClose(data: IAppClosedTopicPayload): Promise<void> {
        this._logger.info(`[onClose]] start, data: ${JSON.stringify(data)}`);
        const mb2cb = await this._mb2CBPromise;
        if (mb2cb) {
            // todo - cant be removed, wrapping the close on mb2cb due to 2 events published from Control Center Widget
            setTimeout(async () => {
                await mb2cb.onClose(data);
            }, 0);
        }
    }

    private async onClientInitSucceeded(): Promise<void> {
        const preloaderManager = await this._preloaderManagerPromise;
        preloaderManager?.onClientInitSucceeded();
    }

    private async onClientInitFailed(data: IClientInitFailed): Promise<void> {
        const preloaderManager = await this._preloaderManagerPromise;
        preloaderManager?.onClientInitFailed(data);
    }

    private async onInactivityTimeout(): Promise<void> {
        const sessionManager = await this._sessionManagerPromise;
        sessionManager?.onInactivityTimeoutMessage();
    }

    private async onHeartBeat(data?: any): Promise<void> {
        const sessionManager = await this._sessionManagerPromise;
        sessionManager?.onHeartbeatMessage(data);
    }

    private async onInitSucceeded(data: IAppInitSucceededTopicPayload): Promise<void> {
        this._logger.info(`[onInitSucceeded]] start, data: ${JSON.stringify(data)}`);
        const mb2cb = await this._mb2CBPromise;
        if (mb2cb) {
            await mb2cb.onInitSucceeded(data);
        }
    }

    private async onInitFailed(data: IAppInitFailedTopicPayload): Promise<void> {
        this._logger.info(`[onInitFailed]] start, data: ${JSON.stringify(data)}`);
        const mb2cb = await this._mb2CBPromise;
        if (mb2cb) {
            await mb2cb.onInitFailed(data);
        }
    }

    private async onError(data: IAppErrorTopicPayload): Promise<void> {
        this._logger.info(`[onError]] start, data: ${JSON.stringify(data)}`);
        const mb2cb = await this._mb2CBPromise;
        if (mb2cb) {
            await mb2cb.onError(data);
        }
    }

    private async closeIntentResult(data: IAppCloseIntentResultTopicPayload): Promise<void> {
        this._logger.info(`[closeIntentResult]] start, data: ${JSON.stringify(data)}`);
        const mb2cb = await this._mb2CBPromise;
        if (mb2cb) {
            await mb2cb.closeIntentResult(data);
        }
    }

    private async onUserInteraction(): Promise<void> {
        this._logger.info(`[onUserInteraction]] start`);
        const mb2cb = await this._mb2CBPromise;
        if (mb2cb) {
            await mb2cb.onUserInteraction();
        }
    }

    private async onAppMinimized(data: IAppMinimizedTopicPayload): Promise<void> {
        this._logger.info(`[onAppMinimized]] start, data: ${JSON.stringify(data)}`);
        const mb2cb = await this._mb2CBPromise;
        if (mb2cb) {
            await mb2cb.onAppMinimized(data);
        }
    }
}
