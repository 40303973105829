import Container from 'typedi';
import {
    DataStoreDeferredObjectToken,
    LocalStorageToken,
    PerformanceToken,
    SessionStorageToken,
    SessionTimerInterval,
} from '../../../../../injection-tokens';
import { Config } from '../../../../../models/enums/Consts';

export const setMutualServices = () => {
    try {
        Container.set(SessionStorageToken, sessionStorage);
        Container.set(LocalStorageToken, localStorage);
        Container.set(PerformanceToken, performance);
        Container.set(SessionTimerInterval, Config.SessionTimerInterval);
        Container.set(DataStoreDeferredObjectToken, dataStoreRefreshDO);
    } catch (err) {
        console.error('setMutualServices failed');
    }
};
