import Container, { Service } from 'typedi';
import { LoggerProvider } from '../logger';
import PendingApi from '../../../APIs/PendingApi';
import { Brand, PendingEventTypeEnum } from '../../models/enums/Consts';
import { Utils } from '../utils';
import { IDisplayPendingParams, IGetPendingAction, IGetPendingActionsParams } from './interfaces';
import { NativeUtils } from '../native/native.utils';
import { IPendingActionState, PendingAction } from './pending.action';
import TokenApi from '../../../APIs/TokenApi';
import PageContextManager from 'page-context-manager';
import { AppState } from '../app-state';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class PendingService {
    private _currentPendingAction: PendingAction;
    private readonly _logger: ILogger;
    private readonly _utils: Utils;
    private readonly _appState: AppState;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('PendingService');
        this._utils = Container.get(Utils);
        this._appState = Container.get(AppState);
        this._currentPendingAction = null;
    }

    public displayPendingOnLogin(token: string, specificNavigation: string, onClose?: Function) {
        const pendingOnLogin = new PendingAction();
        this._setCurrentAction(pendingOnLogin);
        pendingOnLogin.execute(specificNavigation, token, !!onClose, onClose);
    }

    public getPendingActions = async (pendingRequestParams?: IGetPendingActionsParams) => {
        const { response, errorResponse } =
            await PendingApi.GetPendingActions(pendingRequestParams);

        if (errorResponse) {
            this._logger.error(`getPendingActions: error: `, errorResponse);
        }

        return { response, errorResponse };
    };

    public displayPendingPostLogin = async (
        pendingActions: Array<IGetPendingAction>,
        onCloseCallback: Function,
        targetAppProductPackage?: number,
    ): Promise<IPendingActionState> => {
        let returnResult: IPendingActionState;
        if (pendingActions?.length) {
            await NativeUtils.showBottomBar(false);

            for (let action of pendingActions) {
                const tokenResponse = await TokenApi.GetToken();
                if (!tokenResponse.Token) {
                    this._logger.error(`displayPendingWithPendingData: Error retrieving token`);
                    onCloseCallback && onCloseCallback();

                    return {
                        closed: true,
                        completed: false,
                        error: 'error while retrieving token',
                    };
                }
                returnResult = await this._openPending(
                    action,
                    tokenResponse.Token,
                    onCloseCallback,
                    targetAppProductPackage,
                );
                if (returnResult.error) {
                    this._logger.error(
                        `display: Error encountered while launching pending action id: ${action.PendingActionId}, name: ${action.PendingActionName}, error: ${returnResult.error}`,
                    );

                    break;
                }
            }
        }

        return returnResult;
    };

    public getCurrentAction = () => {
        return this._currentPendingAction;
    };

    public showPending = async (params?: IDisplayPendingParams): Promise<IPendingActionState> => {
        const { response, errorResponse } = await this.getPendingActions(params?.requestParams);
        if ((!response?.IsOk && response?.ErrorCode === 100) || !response?.PendingActions?.length) {
            this._logger.debug(
                'displayPending: pending action step skipped, user has no pending action!',
            );
            await this._onClose(params);
            return {
                closed: true,
                completed: true,
                error: null,
            };
        }

        if (errorResponse || !response) {
            await this._onClose(params);
            return {
                closed: true,
                completed: false,
                error: 'error while fetching pending actions',
            };
        }

        return this.displayPendingPostLogin(response.PendingActions, this._onClose);
    };

    private _setCurrentAction = (action: PendingAction) => {
        this._currentPendingAction = action;
    };

    private _openPending = async (
        action: IGetPendingAction,
        token: string,
        onClose: Function,
        targetAppProductPackage?: number,
    ): Promise<IPendingActionState> => {
        const formattedEventType = action.EventTypeId ?? PendingEventTypeEnum.Play;
        const formattedEventTypeName = action.EventTypeName ?? 'Play';

        const isNative = this._utils.findIfIsNative();
        const { mitIdSwitchBackUrl } = PageContextManager.getSiteData();
        const osName = PageContextManager.getDeviceData().osName.toLowerCase();

        var specificNavigation = `<XML>
                <Pending_Event_Type ID="${formattedEventType}" name="${formattedEventTypeName}"></Pending_Event_Type>
                ${isNative ? '<IsNative>1</IsNative>' : ''}
                ${
                    targetAppProductPackage
                        ? `<TargetAppProductPackage>${targetAppProductPackage}</TargetAppProductPackage>`
                        : ''
                }
                ${
                    isNative && mitIdSwitchBackUrl
                        ? `<SwitchBackUrl>${mitIdSwitchBackUrl}</SwitchBackUrl><SwitchBackOS>${osName}</SwitchBackOS>`
                        : ''
                }
            </XML>`;

        const showCloseButton = this._hasCloseButton(formattedEventType);

        const closeFn = showCloseButton && onClose ? onClose : undefined;

        const pendingAction = new PendingAction(action);
        this._setCurrentAction(pendingAction);

        return pendingAction.execute(specificNavigation, token, showCloseButton, closeFn);
    };

    private _hasCloseButton = (eventType: PendingEventTypeEnum): boolean => {
        const brandsWithoutCloseButton = [Brand.Sport_NL];
        const currentBrand = PageContextManager.getBrandData().brandId;

        let showCloseButton = !brandsWithoutCloseButton.some((brand) => brand === currentBrand);

        if (showCloseButton && eventType === PendingEventTypeEnum.OpenApp) {
            showCloseButton = false;
        }

        return showCloseButton;
    };

    private _onClose = async (params: IDisplayPendingParams) => {
        if (params && typeof params.onClose === 'function') {
            params.onClose();
        }
        await this._appState.setBottomBarVisibility();
    };
}
