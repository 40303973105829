import { ClientIntegrationFacadeToken, SessionStorageToken } from '../../injection-tokens';
import { Container, Service } from 'typedi';
import { LoggerProvider } from '../logger';
import { IdsOfElements } from '../../models/enums/Consts';
import {
    MessageBroker,
    IPlayerChannel,
    IRouterChannel,
    ICrmChannel,
    IPaymentChannel,
    ICrossSellChannel,
    IMyAccountOpenChallengesData,
    IB2CPlayerSegmentationData,
    ILogger,
} from '@sparkware/uc-sdk-core';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import { NativeUtils } from '../native/native.utils';
import { IClientIntegrationFacade } from '../client-integration/interfaces/IClientIntegrationFacade';
import PageContextManager from 'page-context-manager';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
} from '@unified-client/event-formatter';
import { ClickStreamTrackingProvider } from '../tracking';
import { IElasticEventData } from '../tracking/models/interfaces/IElasticEventData';
import { TrackingElasticEvent } from '../tracking/enums/consts';

@Service()
export class B2CIntegration {
    private readonly _playerChannel: IPlayerChannel;
    private readonly _paymentChannel: IPaymentChannel;
    private readonly _crmChannel: ICrmChannel;
    private readonly _routerChannel: IRouterChannel;
    private readonly _crossSellChannel: ICrossSellChannel;

    private readonly _sessionStorage: Storage;
    private readonly _clientIntegrationFacade: IClientIntegrationFacade;
    private readonly _logger: ILogger;
    private readonly _eventFormatterBuilderFactory: EventFormatterBuilderFactory;
    private readonly _eventFormatterBuilder: EventFormatterBuilder;
    private readonly _clickStreamTrackingProvider: ClickStreamTrackingProvider;

    constructor() {
        this._clientIntegrationFacade = Container.get(ClientIntegrationFacadeToken);
        this._playerChannel = MessageBroker.getInstance().player;
        this._paymentChannel = MessageBroker.getInstance().payments;
        this._crmChannel = MessageBroker.getInstance().crm;
        this._routerChannel = MessageBroker.getInstance().router;
        this._crossSellChannel = MessageBroker.getInstance().crossSell;
        this._sessionStorage = Container.get(SessionStorageToken);
        this._logger = Container.get(LoggerProvider).getLogger('B2CIntegration');
        this._eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
        this._eventFormatterBuilder =
            this._eventFormatterBuilderFactory.createEventFormatterBuilder('B2CIntegration');

        this.playerSegmentationPublish = this.playerSegmentationPublish.bind(this);
    }

    public showCookieBanner = async (height: Number): Promise<void> => {
        const messageData = { visible: true, height };
        const formatter = this._eventFormatterBuilder.createFormatter('showCookieBanner');
        const eventData: IElasticEventData = {
            event: TrackingElasticEvent.B2CIntegrationShowCookieBanner,
            dataString: JSON.stringify(messageData),
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            'showCookieBanner: start executing',
            eventData,
        );

        const { ReadyToConnect } = this._clientIntegrationFacade;
        if (ReadyToConnect.resolved) {
            this._clientIntegrationFacade.cookieBannerToggleVisibilityPublish(messageData);
        } else {
            return ReadyToConnect.promise.then((ready) => {
                if (ready) {
                    this._clientIntegrationFacade.cookieBannerToggleVisibilityPublish(messageData);
                } else {
                    this._logger.error(
                        'showCookieBanner: Client is unable to receive message broker events, so cookie visibility cannot be changed',
                    );
                }
            });
        }
    };

    public hideCookieBanner = async (): Promise<void> => {
        const messageData = { visible: false };

        const formatter = this._eventFormatterBuilder.createFormatter('hideCookieBanner');
        const eventData: IElasticEventData = {
            event: TrackingElasticEvent.B2CIntegrationHideCookieBanner,
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            'hideCookieBanner: start executing',
            eventData,
        );

        const { ReadyToConnect } = this._clientIntegrationFacade;

        if (ReadyToConnect.resolved) {
            this._clientIntegrationFacade.cookieBannerToggleVisibilityPublish(messageData);
        } else {
            return ReadyToConnect.promise.then((ready) => {
                if (ready) {
                    this._clientIntegrationFacade.cookieBannerToggleVisibilityPublish(messageData);
                } else {
                    this._logger.error(
                        'hideCookieBanner: Client is unable to receive message broker events, so cookie visibility cannot be changed',
                    );
                }
            });
        }
    };

    public playerSegmentationPublish(additionalData?: any) {
        //merging base playerSegmentationData with additional Data
        const playerSegmentationData: IB2CPlayerSegmentationData = {
            ...this._getPlayerSegmentationData(),
            ...additionalData,
        };

        const formatter = this._eventFormatterBuilder.createFormatter('playerSegmentationPublish');
        const eventData: IElasticEventData = {
            event: TrackingElasticEvent.B2CIntegrationPlayerSegmentationPublish,
            dataString: JSON.stringify(playerSegmentationData),
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            'playerSegmentationPublish: start executing',
            eventData,
        );

        this._playerChannel.topics.b2cPlayerSegmentation.publish(
            { publisher: 'B2CIntegration' },
            playerSegmentationData,
        );
    }

    public getCookieContainer() {
        let cookieMessageContainer: string = IdsOfElements.CookieMessage;

        try {
            if (typeof getCookieMessageContainer === 'function') {
                cookieMessageContainer = getCookieMessageContainer() || IdsOfElements.CookieMessage;
            }
        } catch (e) {
            this._logger.error(
                'getCookieContainer: Error encountered while getting the cookie container id.',
                e,
            );
        }

        const formatter = this._eventFormatterBuilder.createFormatter('getCookieContainer');
        const eventData: IElasticEventData = {
            event: TrackingElasticEvent.B2CIntegrationGetCookieContainer,
            dataString: JSON.stringify(cookieMessageContainer),
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            'getCookieContainer: : start executing',
            eventData,
        );

        return cookieMessageContainer;
    }

    public openMyAccount(tabId: number): void {
        const formatter = this._eventFormatterBuilder.createFormatter('openMyAccount');
        const eventData: IElasticEventData = {
            event: TrackingElasticEvent.B2CIntegrationOpenMyAccount,
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            `openMyAccount: tabId:${tabId}`,
            eventData,
        );

        if (tabId) {
            this._playerChannel.topics.CTA_MyAccount_Open.publish(
                { publisher: 'B2CIntegration' },
                {
                    tabId,
                },
            );
            return;
        }
        this._logger.error(
            'b2c-integration | openMyAccount: tabId should be a number and not null/undefined!',
        );
    }

    public openMyChallenges(): void {
        const formatter = this._eventFormatterBuilder.createFormatter('openMyChallenges');
        const eventData: IElasticEventData = {
            event: TrackingElasticEvent.B2CIntegrationOpenMyChallenges,
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            'openMyChallenges: start executing',
            eventData,
        );

        this._playerChannel.topics.CTA_MyAccount_Open_Challenges.publish(
            { publisher: 'B2CIntegration' },
            {} as IMyAccountOpenChallengesData,
        );
    }

    public openExternalLink(url: string): void {
        const formatter = this._eventFormatterBuilder.createFormatter('openExternalLink');
        const eventData: IElasticEventData = {
            event: TrackingElasticEvent.B2CIntegrationOpenExternalLink,
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            `openExternalLink: url:${url}`,
            eventData,
        );
        if (url) {
            NativeUtils.openExternalUrl(url);
            return;
        }
        this._logger.error(
            'b2c-integration | openExternalLink: url should be a string and not null/undefined!',
        );
    }

    public openCashier(): void {
        const formatter = this._eventFormatterBuilder.createFormatter('openCashier');
        const eventData: IElasticEventData = {
            event: TrackingElasticEvent.B2CIntegrationOpenCashier,
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            'openCashier: start executing',
            eventData,
        );
        this._paymentChannel.topics.CTA_Cashier_Open.publish({ publisher: 'B2CIntegration' }, {});
    }

    public openRich(campaignId: number): void {
        const formatter = this._eventFormatterBuilder.createFormatter('openRich');
        const eventData: IElasticEventData = {
            event: TrackingElasticEvent.B2CIntegrationOpenRich,
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            `openRich: campaignId:${campaignId}`,
            eventData,
        );
        if (campaignId) {
            this._crmChannel.topics.CTA_RICH_Open.publish(
                { publisher: 'B2CIntegration' },
                {
                    CampaignId: campaignId,
                },
            );
            return;
        }
        this._logger.error(
            'b2c-integration | openRich: campaignId should be a number and not null/undefined!',
        );
    }

    public openPokerRaces(): void {
        const formatter = this._eventFormatterBuilder.createFormatter('openPokerRaces');
        const eventData: IElasticEventData = {
            event: TrackingElasticEvent.B2CIntegrationOpenPokerRaces,
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            'openPokerRaces: start executing',
            eventData,
        );

        this._playerChannel.topics.CTA_MyAccount_Open.publish(
            { publisher: 'B2CIntegration' },
            { tabId: 14 },
        );
    }

    public openRedeem(): void {
        const formatter = this._eventFormatterBuilder.createFormatter('openRedeem');
        const eventData: IElasticEventData = {
            event: TrackingElasticEvent.B2CIntegrationOpenRedeem,
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            'openRedeem: start executing',
            eventData,
        );

        this._playerChannel.topics.CTA_MyAccount_Open.publish(
            { publisher: 'B2CIntegration' },
            { tabId: 8 },
        );
    }

    public openCasino(): void {
        const formatter = this._eventFormatterBuilder.createFormatter('openCasino');
        const eventData: IElasticEventData = {
            event: TrackingElasticEvent.B2CIntegrationOpenCasino,
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            'openCasino: start executing',
            eventData,
        );

        this._crossSellChannel.topics.CTA_CasinoClient_Open.publish(
            { publisher: 'B2CIntegration' },
            {},
        );
    }

    public openSport(): void {
        const formatter = this._eventFormatterBuilder.createFormatter('openSport');
        const eventData: IElasticEventData = {
            event: TrackingElasticEvent.B2CIntegrationOpenSport,
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            'openSport: start executing',
            eventData,
        );

        this._crossSellChannel.topics.CTA_SportClient_Open.publish(
            { publisher: 'B2CIntegration' },
            {},
        );
    }

    public openClientPage(page: string): void {
        const formatter = this._eventFormatterBuilder.createFormatter('openClientPage');
        const eventData: IElasticEventData = {
            event: TrackingElasticEvent.B2CIntegrationOpenClientPage,
        };
        this._clickStreamTrackingProvider.sendElasticEvent(
            formatter,
            `openClientPage: page:${page}`,
            eventData,
        );

        if (page) {
            this._routerChannel.topics.NavigateByCode.publish(
                { publisher: 'B2CIntegration' },
                { url: page },
            );
            return;
        }

        this._logger.error(
            'b2c-integration | openClientPage: page should be a string and not null/undefined!',
        );
    }

    private _getPlayerSegmentationData(): IB2CPlayerSegmentationData {
        const cId = this._sessionStorage.getItem(StorageItemEnum.CID);
        const { isFtd, isUserVip } = PageContextManager.getUserData();

        const data: IB2CPlayerSegmentationData = {
            cId: cId,
            isFTD: !!isFtd,
            isVIP: !!isUserVip,
            isAuthenticated: !!cId,
            playerStatusId: null,
        };

        return data;
    }
}
