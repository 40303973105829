import { LoggerProvider } from '../logger';
import { ILogger, IUiChannel, MessageBroker } from '@sparkware/uc-sdk-core';
import { WebLoginMode, WebLoginSDKName } from './enums/const';
import { Container } from 'typedi';
import DeferredObject from '../../../Modules/Utils/DeferredObject';
import { WindowToken } from '../../injection-tokens';
import { IAuthenticationStorage } from '../authentication/models';
import PageContextManager from 'page-context-manager';
import { IMrGreenMigration } from '../mr-green-migration/models/interfaces/IMrGreenMigration';
import { LoaderManager } from '../../loaders/LoaderManager';

const HEADER = { publisher: 'UCF-WebLoginComponent' };

export interface IWebLoginFormModeOptions {
    username: string;
    showrememberme?: boolean;
}

export interface IWebLoginFormMode {
    type: WebLoginMode;
    modeoptions?: IWebLoginFormModeOptions;
}

export interface IWebLoginForm {
    mode: IWebLoginFormMode;
    enablecaptcha?: boolean;
    errorcodeid?: number;
}

export interface IWebLoginInitObject {
    subbrandid: any;
    languagecode: any;
    productpackageid: number;
    formoptions: IWebLoginForm;
    showclosebutton: any;
    migrationStateId?: number;
    appSwitch?: IAppSwitch;
}

export interface IAppSwitch {
    switchbackurl: string;
    switchbackos: string;
}

export interface IWebLoginEventsObject {
    onload: () => void;
    onerror: (e: { errortext: any }) => void;
    onexternallink: (e: { target: any }) => void;
    onsubmit: (eventData: any) => void;
    onregister: () => void;
    onfyp: () => void;
    onfyu: () => void;
    onclose: () => void;
    ontouchId: () => void;
    onupdated: () => void;
    onredirectlink: (eventData: any) => void;
}

export const getConfig = (savedUsername: string): IWebLoginForm => ({
    mode: {
        type: WebLoginMode.Regular,
        modeoptions: {
            username: savedUsername,
            showrememberme: false,
        },
    },
    errorcodeid: 0,
    enablecaptcha: false,
});

export class WebLoginComponent {
    private _webLoginDO: DeferredObject<void>;
    private _isLoading: boolean = false;
    private _isLoaded: boolean = false;
    private _webLoginScriptLoaded: boolean = false;

    public IsFirstTouchLogin: boolean = false;
    private _initObject: IWebLoginInitObject;
    private _eventsObject: IWebLoginEventsObject;

    private _webLoginInstance: any;

    private readonly _logger: ILogger;
    private readonly _window: Window;
    private readonly _uiChannel: IUiChannel;

    private get _mrGreenMigrationPromise(): Promise<IMrGreenMigration> {
        return LoaderManager.Instance.MrGreenMigrationLoader.Instance;
    }

    private get _authenticationStoragePromise(): Promise<IAuthenticationStorage> {
        return LoaderManager.Instance.AuthenticationStorageLoader.Instance;
    }

    public get IsLoaded(): boolean {
        return this._isLoaded;
    }

    constructor(initObject: IWebLoginInitObject, eventsObject: IWebLoginEventsObject) {
        this._window = Container.get(WindowToken);
        this._logger = Container.get(LoggerProvider).getLogger('WebLoginService');
        this._uiChannel = MessageBroker.getInstance().ui;
        this._webLoginDO = new DeferredObject<any>();
        this._initObject = initObject;
        this._eventsObject = eventsObject;
    }

    public GetWebLogin = async (): Promise<any> => {
        await this._tryInitWebLogin();
        return this._webLoginDO.promise;
    };

    public Show = async (savedUsername, ...params): Promise<any> => {
        await this.GetWebLogin();
        const authenticationStorage = await this._authenticationStoragePromise;

        const options = getConfig(savedUsername);
        await this.UpdateForm(options);

        const { className } = authenticationStorage.getLoginOptions();
        if (className) {
            this._window.document.body.classList.add(className);
        }
    };

    public ProcessResponse = async (response): Promise<any> => {
        await this.GetWebLogin();

        return RL.PF.Web.LoginUi.Login.processresponse(response);
    };

    public Hide = async (...params): Promise<any> =>
        this.GetWebLogin().then((webLogin) => webLogin.hide(...params));

    public UpdateForm = async (...params): Promise<any> =>
        this.GetWebLogin().then((webLogin) => webLogin.updateform(...params));

    public Register = async (...params): Promise<any> =>
        this.GetWebLogin().then((webLogin) => webLogin.registerClicked(...params));

    public ResetLoginForm = async (savedUsername: string): Promise<void> =>
        this.GetWebLogin().then((webLogin) => webLogin.updateform(getConfig(savedUsername)));

    private _tryInitWebLogin = async () => {
        if (!this._isLoading && !this._isLoaded) {
            this._isLoading = true;
            try {
                if (!this._webLoginScriptLoaded) {
                    this._webLoginScriptLoaded = await this._getWebLoginScript();
                }

                if (this._webLoginScriptLoaded) {
                    this._getScriptCallback();
                } else {
                    this._logger.log('_initIfNeeded: aborted attempt...');
                }
            } catch (error) {
                this._getScriptFailCallback(error);
            }
        }
    };

    private _getWebLoginScript = (): Promise<boolean> =>
        new Promise((resolve, reject) => {
            const webLoginSRC = PageContextManager.getUrlResourceData().webLoginSRC;
            const url = webLoginSRC + WebLoginSDKName;

            let script: any = document.createElement('script');

            script.async = true;
            script.onerror = reject;
            script.onload = script.onreadystatechange = async (_, isAbort) => {
                if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
                    script.onload = script.onreadystatechange = null;
                    script = undefined;
                    resolve(!isAbort);
                }
            };

            script.src = url;
            script.setAttribute('data-ot-ignore', '');
            document.body.appendChild(script);
        });

    private _getScriptFailCallback = (error) => {
        this._logger.error(`_getScriptFailCallback: error getting web login js, error:${error}`);
    };

    private _getScriptCallback = async () => {
        this._logger.log('_getScriptCallback: finish getting web login js');
        if (!this._isLoaded) {
            this._webLoginInstance = new RL.PF.Web.LoginUi.Login(
                this._initObject,
                this._getEventsObject(),
            );
        } else {
            this._uiChannel.topics.webLoginLoaded.publish(HEADER, {});
        }
    };

    private _getEventsObject = () => ({
        onload: async () => {
            this._logger.log('onload callback...');
            if (!this._webLoginDO.resolved && !this._isLoaded) {
                this._isLoading = false;
                this._isLoaded = true;
                this._uiChannel.topics.webLoginLoaded.publish(HEADER, {});
                this._webLoginDO.resolve(this._webLoginInstance);
                const mrGreenMigration = await this._mrGreenMigrationPromise;
                if (mrGreenMigration?.MigrationAutoLoginOcurred) {
                    this._webLoginInstance.setDeviceUUIDCookie(
                        mrGreenMigration.BusinessCorrelationId,
                    );
                    mrGreenMigration.MigrationAutoLoginOcurred = false;
                }
            }

            this._eventsObject.onload();
        },
        onerror: (e) => {
            this._logger.error('_onError callback... ', e);
            this._isLoading = false;
            this._isLoaded = true;
            this._webLoginDO.reject(e);

            this._webLoginDO = new DeferredObject<void>();
            this._eventsObject.onerror(e);
        },
        onexternallink: (e) => this._eventsObject.onexternallink(e),
        onsubmit: (eventData) => this._eventsObject.onsubmit(eventData),
        onregister: () => this._eventsObject.onregister(),
        onfyp: () => this._eventsObject.onfyp(),
        onfyu: () => this._eventsObject.onfyu(),
        onclose: () => this._eventsObject.onclose(),
        ontouchId: () => this._eventsObject.ontouchId(),
        onupdated: () => this._eventsObject.onupdated(),
        onredirectlink: (eventData) => this._eventsObject.onredirectlink(eventData),
    });
}
