import Container, { Service } from 'typedi';
import {
    INativeChannel,
    IPlayerChannel,
    IThemeModeUpdateData,
    ITopicEnvelope,
    IUserInteractionChannel,
    MessageBroker,
    INativeThemeModeUpdateData,
    ILogger,
} from '@sparkware/uc-sdk-core';

import { LoggerProvider } from '../logger';
import { ThemeService } from './theme.service';
import { Utils } from '../utils';
import { INativeService } from '../native/models/INativeService';
import { LoaderManager } from '../../loaders/LoaderManager';

@Service()
export class ThemeController {
    private readonly _logger: ILogger;

    private readonly _playerChannel: IPlayerChannel;
    private readonly _userInteractionChannel: IUserInteractionChannel;
    private readonly _nativeChannel: INativeChannel;

    private readonly _themeService: ThemeService;
    private readonly _utils: Utils;

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('ThemeController');

        this._playerChannel = MessageBroker.getInstance().player;
        this._userInteractionChannel = MessageBroker.getInstance().userinteraction;
        this._nativeChannel = MessageBroker.getInstance().native;

        this._themeService = Container.get(ThemeService);
        this._utils = Container.get(Utils);

        this._bindTopicSubscribers();

        this._bindThemeMode();
    }

    private _bindTopicSubscribers = () => {
        const isThemeModeEnabled = this._themeService.findIfIsThemeModeEnabled();
        if (isThemeModeEnabled) {
            this._playerChannel.topics.playerPreferences.subscribe(this._onPlayerPreferences);

            this._userInteractionChannel.topics.themeModeUpdate.subscribe(this._onThemeModeUpdate);

            const isNative = this._utils.findIfIsNative();
            if (isNative)
                this._nativeChannel.topics.themeModeUpdate.subscribe(
                    this._onNativeSystemThemeModeUpdate,
                );
        } else this._logger.debug('Theme mode is not enabled, not subscribing to topics');
    };

    private _bindThemeMode = () => {
        const isThemeModeEnabled = this._themeService.findIfIsThemeModeEnabled();

        if (isThemeModeEnabled) {
            const isNative = this._utils.findIfIsNative();
            if (isNative) {
                const nativeSystemThemeMode = this._themeService.getNativeSystemThemeMode();

                if (nativeSystemThemeMode)
                    this._themeService.setNativeThemeMode(nativeSystemThemeMode);
                else this._logger.warn('Not found native system theme mode');
            }
        } else this._logger.debug('Theme mode is not enabled, not binding');
    };

    private _onPlayerPreferences = (data, envelope: ITopicEnvelope) => {
        this._logger.debug('Begin process player preferences topic request');

        if (envelope?.headers?.version === 2) {
            if (data) {
                const themeMode = this._themeService.parseThemeMode(data.themeMode);

                if (themeMode) this._themeService.setThemeMode(themeMode);
            } else this._logger.error('No theme mode update data received');
        } else this._logger.debug('Topic request is not of version 2');
    };

    private _onThemeModeUpdate = async (data: IThemeModeUpdateData, envelope: ITopicEnvelope) => {
        this._logger.debug('Begin process theme mode update topic request');

        if (data) {
            if (envelope.headers.publisher !== 'ThemeService') {
                const themeMode = this._themeService.parseThemeMode(data.themeMode);
                if (themeMode) {
                    this._themeService.setThemeMode(themeMode);

                    (await this._nativeServicePromise)?.themeModeUpdate(themeMode);
                }
            }
        } else this._logger.error('No theme mode update data received');
    };

    private _onNativeSystemThemeModeUpdate = (data: INativeThemeModeUpdateData) => {
        this._logger.debug('Begin process native theme mode update topic request');

        if (data) {
            const currentThemeMode = this._themeService.getThemeMode();

            if (!currentThemeMode) {
                const themeMode = this._themeService.parseThemeMode(data.themeMode);

                if (themeMode) {
                    this._themeService.setNativeThemeMode(themeMode);
                    this._themeService.sendThemeModeUpdate(themeMode);
                }
            } else this._logger.debug('Theme mode was already selected, not updating');
        }
    };
}
