import Container, { Service } from 'typedi';
import ContextApi from '../../../APIs/ContextApi';
import {
    DataStoreDeferredObjectToken,
    UserDataStoreDeferredObjectToken,
} from '../../injection-tokens';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import { LoggerProvider } from '../logger';
import {
    LocalSimpleStoreService,
    SessionSimpleStoreService,
} from '../storage/implementations/simple-store';
import DeferredObject from '../../../Modules/Utils/DeferredObject';
import { Utils } from '../utils';
import { ApiErrorCodes, StatusCodes } from '../../models/enums/Consts';
import { ClientsFrameworkLogoutService } from '../external/clients-framework';
import { ClickStreamTrackingProvider } from '../tracking';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
} from '@unified-client/event-formatter';
import PageContextManager, { AuthenticatedPageContextData } from 'page-context-manager';
import TokenApi from '../../../APIs/TokenApi';
import { ISessionUserData } from './interfaces/ISessionUserData';
import { ApiResponse } from 'api';
import { AuthenticationUtils } from '../authentication/utils/authentication.utils';
import { UrlUtils } from '../utils/urlUtils';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class SessionState {
    private _userDataStoreDeferredObject: DeferredObject<ISessionUserData | void>;
    private readonly _localSimpleStoreService: LocalSimpleStoreService;
    private readonly _sessionSimpleStoreService: SessionSimpleStoreService;
    private readonly _logger: ILogger;
    private readonly _utils: Utils;
    private readonly _dataStoreDO: DeferredObject<void>;
    private readonly _clickStreamTrackingProvider: ClickStreamTrackingProvider;
    private readonly _eventFormatterBuilder: EventFormatterBuilder;
    private readonly _authenticationUtils: AuthenticationUtils;
    private readonly _urlUtils: UrlUtils;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('SessionState');
        this._localSimpleStoreService = Container.get(LocalSimpleStoreService);
        this._sessionSimpleStoreService = Container.get(SessionSimpleStoreService);
        this._utils = Container.get(Utils);
        this._urlUtils = Container.get(UrlUtils);
        this._dataStoreDO = Container.get(DataStoreDeferredObjectToken);
        this._userDataStoreDeferredObject = Container.get(UserDataStoreDeferredObjectToken);
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder =
            eventFormatterBuilderFactory.createEventFormatterBuilder('SessionState');
        this._authenticationUtils = Container.get(AuthenticationUtils);

        const isNativeSdk = this._utils.isNativeSDK();
        if (!isNativeSdk) {
            const isRefreshUserState = this._isRefreshUserState();
            if (isRefreshUserState) this._refreshUserState();
            else this._setUserDataStoreReady(undefined);
        }
    }

    public getDataStoreReadyPromise() {
        const userDataStoreDeferred = Container.get(UserDataStoreDeferredObjectToken);

        return userDataStoreDeferred.promise;
    }

    private _isRefreshUserState = () => {
        const isAutoLoginRequest = this._urlUtils.isAutologinRequest();
        if (this._utils.isBossMode() || isAutoLoginRequest) {
            return false;
        }
        const authorizationData = this._authenticationUtils.getAuthorizationData();
        const expirationData = this._authenticationUtils.getExpirationData();

        return !!(authorizationData && expirationData);
    };

    private _setUserStateStorage(authenticatedPageContextdata: AuthenticatedPageContextData): void {
        PageContextManager.updatePageContextAuthenticated(authenticatedPageContextdata);
        const cid = authenticatedPageContextdata.user?.cid;
        if (cid) {
            this._sessionSimpleStoreService.set(StorageItemEnum.CID, cid);
            this._sessionSimpleStoreService.set(StorageItemEnum.LoginNotification, 'true');
        }
    }
    private _getUserData = async (): Promise<ApiResponse<ISessionUserData, any>> => {
        try {
            const authenticatedPageContextPromise = ContextApi.GetAuthenticatedContextData();
            const storeStatePromise = ContextApi.GetStoreState();
            const tokenPromise = TokenApi.GetToken();

            const authenticatedPageContextData = await authenticatedPageContextPromise;
            const storeStateResponse = await storeStatePromise;
            const tokenResponse = await tokenPromise;

            if (
                authenticatedPageContextData.errorResponse ||
                storeStateResponse.errorResponse ||
                !tokenResponse
            ) {
                return {
                    errorResponse: [
                        authenticatedPageContextData.errorResponse,
                        storeStateResponse.errorResponse,
                        !tokenResponse ? 'Error while getting token' : undefined,
                    ].filter((error) => !!error),
                };
            }

            const authenticatedData = authenticatedPageContextData.response;

            return {
                response: {
                    AuthenticateData: {
                        CID: authenticatedData.user.cid,
                        Token: tokenResponse.Token,
                        RegulationTypeID: authenticatedData.regulation.regulationTypeId,
                        RestrictedApps: [],
                        BatchNumber: authenticatedData.user.batchNumber,
                        HasKambiBets: authenticatedData.user.hasKambiBets || false,
                    },
                    PageContextData: authenticatedPageContextData.response,
                    StoreState: storeStateResponse.response,
                },
            };
        } catch (e) {
            this._logger.error('_getUserData', e);
            return null;
        }
    };

    private _refreshUserState = async (): Promise<void> => {
        const userData = await this._getUserData();

        if (userData.response) {
            this._logger.log(
                'Unified Client Service refresh state details successfully retrieved!',
            );

            this._setUserStateStorage(userData.response.PageContextData);

            this._setUserDataStoreReady(userData.response);
        } else {
            this._handleUserRefreshStateError(userData.errorResponse);
            this._setUserDataStoreReady();
        }
    };

    private _setUserDataStoreReady(data?: ISessionUserData): void {
        this._dataStoreDO.promise.then(() => {
            this._userDataStoreDeferredObject.resolve(data);
        });
    }

    private _handleUserRefreshStateError = (errorResponse: Array<any>) => {
        this._logger.log('Unified Client Service refresh state details could not be retrieved!');

        const isTerminatedSession = errorResponse.some(
            (error) =>
                (error?.status == StatusCodes.BAD_REQUEST &&
                    error?.data?.error?.code === ApiErrorCodes.MissingUserData) ||
                error?.status == StatusCodes.UNAUTHORIZED ||
                error?.status == StatusCodes.FORBIDDEN,
        );

        if (isTerminatedSession) {
            const formatter = this._eventFormatterBuilder.createFormatter(
                '_handleUserRefreshStateError',
            );

            const utils = Container.get(Utils);

            this._resetUserStateStorage();

            const eventData = formatter.formatUCEvent(
                {
                    message: 'Session was terminated while the browser was in background',
                },
                { correlationID: utils.generateCorrelationID() },
                { event: 'page-refresh-due-to-out-of-sync-sessions' },
            );

            this._clickStreamTrackingProvider.sendEventV2(eventData);
        }
    };

    private _resetUserStateStorage(): void {
        this._authenticationUtils.removeAuthorizationData();
        this._localSimpleStoreService.remove(StorageItemEnum.GeolocationData);
        this._localSimpleStoreService.remove(StorageItemEnum.PostLoginActionsInterrupted);
        this._localSimpleStoreService.remove(StorageItemEnum.PostLoginActionsRegistrationAutoLogin);
        const userSessionStorageItems = [StorageItemEnum.IsPostLoginActivityHistoryProcessed];
        userSessionStorageItems.forEach((item) => this._sessionSimpleStoreService.remove(item));
        Container.get(ClientsFrameworkLogoutService).removeLobbyCookie();
        //other items are removed by CheckLogout method from SportApi - called on page loading
        //TO DO - move code here and make a new module for Logout/Login...
    }
}
