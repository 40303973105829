import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { IPostLogin } from '../../post-login/models/IPostLogin';
import {
    IDialogShowData,
    ILogger,
    IUiChannel,
    MessageBroker,
    PostLoginActionNameEnum,
} from '@sparkware/uc-sdk-core';
import { UIService } from '../../ui';

@Service()
export class UIChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _uiChannel: IUiChannel;
    private readonly _uiService: UIService;

    private get _postLoginPromise(): Promise<IPostLogin> {
        return LoaderManager.Instance.PostLoginLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('UIChannelSubscriber');
        this._uiService = Container.get(UIService);
        this._uiChannel = MessageBroker.getInstance().ui;
        this._uiChannel.topics.favouriteSportsClosed.subscribe(
            this.onFavouriteSportsClosed.bind(this),
        );
        this._uiChannel.topics.Dialog.subscribe(this.onDialog.bind(this));
    }

    private async onFavouriteSportsClosed(): Promise<void> {
        (await this._postLoginPromise)?.completeAction(
            PostLoginActionNameEnum.StartFavouriteSportsFlow,
        );
    }

    private async onDialog(data: IDialogShowData): Promise<void> {
        this._uiService.handleDialog(data);
    }
}
