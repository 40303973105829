import { Container, Service } from 'typedi';
import { LoggerProvider } from '../logger';
import {
    IAppClosedTopicPayload,
    IRouterChannel,
    ISessionChannel,
    MessageBroker,
    INavigateByCodeTopicPayload,
    UserMode,
    ILogger,
} from '@sparkware/uc-sdk-core';
import {
    IdsOfElements,
    NAVIGATION_IGNORE_ATTRIBUTE,
    IMAGE_LINK_REGEX,
    ClientProvider,
    StaticPaths,
    ViewSource,
    Features,
    Brand,
} from '../../models/enums/Consts';
import {
    ClientIntegrationFacadeToken,
    FeatureAvailabilityToken,
    UserContextToken,
    WindowToken,
} from '../../injection-tokens';
import { CloseReason } from '../app-launcher/enums';
import ContentApi from '../../../APIs/ContentApi';
import { IUrlHandlerOptions } from './models/IUrlHandlerOptions';
import { Navigation } from '../navigation';
import { PreloaderWrapper } from '../ui/preloader/preloader-wrapper';
import { Utils } from '../utils';
import { ViewInjector } from '../view-injector';
import { WebLoginService } from '../web-login/web.login.service';
import { parse } from 'url';
import { CommonService } from '../external/clients-framework';
import { UserareaService } from '../external/userarea';
import { UrlUtils } from '../utils/urlUtils';
import { RouterUtils } from './router.utils';
import { IBIEventHandler } from '../bi/models/IBIEventHandler';
import { IFeatureAvailability } from '../feature/feature-availability/feature-availability-interface';
import PageContextManager from 'page-context-manager';
import { SiteSegmentationContext } from '../site-segmentation-context';
import { IAppCatalog } from '../app-catalog/interfaces';
import { BrandUtils } from '../../../Modules/Utils/BrandUtils';
import { ICRM } from '../crm/models';
import { IClientIntegrationFacade } from '../client-integration/interfaces/IClientIntegrationFacade';
import { LoaderManager } from '../../loaders/LoaderManager';
import { IAppLauncher } from '../app-launcher/models/IAppLauncher';
import { IUserContext } from '../user-context/user-context-interface';
import StringHelper from '../../../Modules/Utils/StringUtils';

@Service()
export class RouterService {
    private _lastUrl: string;
    private readonly _logger: ILogger;
    private readonly _viewInjector: ViewInjector;
    private readonly _window: Window;
    private readonly _utils: Utils;
    private readonly _routerChannel: IRouterChannel;
    private readonly _sessionChannel: ISessionChannel;
    private readonly _navigation: Navigation;
    private readonly _preloaderWrapper: PreloaderWrapper;
    private readonly _clientProvider: string;
    private readonly _sessionManagerChannel: ISessionChannel;
    private readonly _userareaService: UserareaService;
    private readonly _commonService: CommonService;
    private readonly _routerUtils: RouterUtils;
    private readonly _urlUtils: UrlUtils;
    private readonly _featureAvailability: IFeatureAvailability;
    private readonly _siteSegmentationContext: SiteSegmentationContext;
    private readonly _clientIntegrationFacade: IClientIntegrationFacade;
    private readonly _userContext: IUserContext;

    private get _crmPromise(): Promise<ICRM> {
        return LoaderManager.Instance.CRMLoader.Instance;
    }

    private get _appCatalogPromise(): Promise<IAppCatalog> {
        return LoaderManager.Instance.AppCatalogLoader.Instance;
    }

    private get _appLauncherPromise(): Promise<IAppLauncher> {
        return LoaderManager.Instance.AppLauncherLoader.Instance;
    }

    private get _biEventHandlerPromise(): Promise<IBIEventHandler> {
        return LoaderManager.Instance.BIEventHandlerLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('RouterService');
        this._window = Container.get(WindowToken);
        this._viewInjector = Container.get(ViewInjector);
        this._utils = Container.get(Utils);
        this._urlUtils = Container.get(UrlUtils);
        this._routerChannel = MessageBroker.getInstance().router;
        this._sessionChannel = MessageBroker.getInstance().session;
        this._navigation = Container.get(Navigation);
        this._preloaderWrapper = Container.get(PreloaderWrapper);
        this._sessionManagerChannel = MessageBroker.getInstance().session;
        this._userareaService = Container.get(UserareaService);
        this._commonService = Container.get(CommonService);
        this._routerUtils = Container.get(RouterUtils);
        this._clientProvider = this._utils.getClientProvider();
        this._featureAvailability = Container.get(FeatureAvailabilityToken);
        this._siteSegmentationContext = Container.get(SiteSegmentationContext);
        this._clientIntegrationFacade = Container.get(ClientIntegrationFacadeToken);
        this._userContext = Container.get(UserContextToken);

        this.handleClickEvents = this.handleClickEvents.bind(this);
        this.handleCodeNavigation = this.handleCodeNavigation.bind(this);
        this.handlePopStateEvents = this.handlePopStateEvents.bind(this);
        this.handleChangeBrowserURI = this.handleChangeBrowserURI.bind(this);
    }

    public async NavigateTo(url: string): Promise<void> {
        return this._urlHandler(url, { clientNavigated: false });
    }

    public async handleClickEvents(event: MouseEvent): Promise<void> {
        const elementTarget = event.target as HTMLElement;
        if (this._isNavigationIgnored(elementTarget)) {
            return;
        }

        const closestLink =
            typeof elementTarget?.closest === 'function' ? elementTarget.closest('a') : undefined;

        if (!closestLink) return;

        const url = closestLink.getAttribute('href');
        const target = closestLink.getAttribute('target');

        if (!url) return;

        this._utils.ScrollToTop();

        const parsedUrl = parse(url, true);
        if (parsedUrl.protocol?.startsWith('javascript')) return;
        if (IMAGE_LINK_REGEX.test(url)) {
            event.preventDefault();
            return;
        }

        if (this._isSameLocation(parsedUrl)) {
            this._logger.log(
                'Trying to navigate to the same location. Stopping event from propagation...',
            );
            event.preventDefault();
            this._clientIntegrationFacade.navigatePublish(url);
            return;
        }

        if (parsedUrl.query.deeplink) {
            this._navigation.HandleDeeplinks(url);

            if (parsedUrl.pathname === null) {
                event.preventDefault();
                return;
            }
        }

        this._logger.log(`Handling click event - navigating to "${url || '[Invalid link]'}"`);

        const options: IUrlHandlerOptions = {
            event,
            clientNavigated: false,
            target,
        };

        if (parsedUrl.query.deeplink) await this._urlHandler(parsedUrl.pathname, options);
        else await this._urlHandler(url, options);
    }

    public async handlePopStateEvents(event: PopStateEvent): Promise<void> {
        this._logger.log(`Handling popstate event - navigating to "${this._window.location.href}"`);

        const url = this._urlUtils.getURLWithoutHost(this._window.location.href);
        const parsedUrl = parse(url);
        const parsedLastUrl = this._lastUrl ? parse(this._lastUrl) : undefined;

        this._handleMainViewAppClosing(parsedLastUrl).catch((err) => {
            this._logger.error(`failed to run _handleMainViewAppClosing function`, err);
        });

        if (parsedUrl.pathname === parsedLastUrl?.pathname) {
            return;
        }

        const options: IUrlHandlerOptions = {
            event,
            clientNavigated: false,
        };

        await this._urlHandler(url, options);
        this._utils.ScrollToTop();
    }

    public async handleChangeBrowserURI({ uri }): Promise<void> {
        this._utils.ScrollToTop();

        this._logger.log(`Handling change browser uri event - navigating to: "${uri}"`);

        const url = this._urlUtils.getURLWithoutHost(uri);
        const parsedUrl = parse(url);
        const parsedLastUrl = this._lastUrl ? parse(this._lastUrl) : undefined;
        if (parsedUrl.pathname === parsedLastUrl?.pathname) {
            return;
        }

        const options: IUrlHandlerOptions = {
            clientNavigated: true,
        };

        await this._urlHandler(url, options);
    }

    public async handleCodeNavigation(data: INavigateByCodeTopicPayload): Promise<void> {
        this._sessionChannel.topics.webLoginClosed.publish({ publisher: 'UCF.RouterService' }, {});
        if (!data) {
            this._logger.warn(`handleCodeNavigation: Invalid data, execution will stop.`);
        }
        this._utils.ScrollToTop();

        if (!data.url.startsWith('/')) data.url = `/${data.url}`;

        const isClientNavigation = this._routerUtils.isClientNavigation(data.url);
        if (!isClientNavigation) {
            const { brandId } = PageContextManager.getBrandData();
            if (brandId === Brand.Sport_MG_DK) {
                data.url = this._urlUtils.getURLWithBase(data.url);
            }
        }

        this._logger.log(`Handling code navigation - navigating to "${data.url}"`);

        if (this._utils.findIfIsNative()) {
            await this._closeOverlappingApps(data.url);
        }

        const options: IUrlHandlerOptions = {
            clientNavigated: false,
            isReplaceState: !!data?.isReplaceState,
            state: data?.state,
        };

        await this._urlHandler(data.url, options);
    }

    public async _handleMainViewApp(url: string = null): Promise<boolean> {
        let needRedirect: boolean = false;
        const currentLocation = this._urlUtils.getNoLangURL(
            this._urlUtils.getURLWithoutHost(url ? url : this._window.location.href),
        );
        if (currentLocation.includes('app/')) {
            const appCatalog = await this._appCatalogPromise;
            await appCatalog.getAppsCatalog();
            const appNavigation = await appCatalog.getAppNavigationData(currentLocation);

            if (!!appNavigation) {
                const appData = await appCatalog.getAppCatalogData(appNavigation.appID);
                if (
                    appData.app.userMode == UserMode.Authenticated &&
                    !this._userContext.IsAuthenticated
                ) {
                    this._sessionManagerChannel.topics.appClosing.publish(
                        { publisher: 'RouterService' },
                        {
                            appID: appNavigation?.appID,
                        } as IAppClosedTopicPayload,
                    );

                    const navigationUrl = BrandUtils.isPokerClient()
                        ? StaticPaths.PokerRoot
                        : StaticPaths.Root;

                    await this.NavigateTo(navigationUrl);
                    if (!StringHelper.isNullOrWhiteSpaces(url)) {
                        this._urlUtils.historyPushState(
                            {},
                            this._window.document.title,
                            this._urlUtils.getLangURL(navigationUrl),
                        );
                    }
                    needRedirect = true;
                }
            }
        }
        return needRedirect;
    }

    private _isNavigationIgnored = (element: HTMLElement): boolean => {
        if (!element) return false;

        if (
            typeof element.getAttribute === 'function' &&
            element.getAttribute(NAVIGATION_IGNORE_ATTRIBUTE) === 'true'
        ) {
            return true;
        }

        return this._isNavigationIgnored(element.parentElement);
    };

    private _computeRelativeUrl(url: string) {
        const parsedURL = new URL(url, this._window.location.origin);

        if (!parsedURL.pathname.includes('.') && !parsedURL.pathname.endsWith('/')) {
            parsedURL.pathname = parsedURL.pathname + '/';
        }

        return this._urlUtils.getURLWithoutHost(parsedURL.toString());
    }

    private async _urlHandler(
        url: string,
        options: IUrlHandlerOptions = { clientNavigated: false, isReplaceState: false },
    ): Promise<void> {
        const { target, event } = options;

        this._logger.log(`NavigationURL: ${decodeURIComponent(url)}`);

        this._preloaderWrapper.removeFailed();

        if (this._isManagedNavigation(url, target)) {
            this._handleMainViewAppClosing().catch((err) => {
                this._logger.error(`failed to run _handleMainViewAppClosing function`, err);
            });
            let relativeUrl = this._computeRelativeUrl(url);
            this._lastUrl = relativeUrl;

            this._routerChannel.topics.Navigate.publish(
                {
                    publisher: 'UCF.RouterService',
                },
                decodeURIComponent(this._urlUtils.getNoLangURL(relativeUrl)),
            );

            this._routerChannel.topics.NavigateV2.publish(
                {
                    publisher: 'UCF.RouterService',
                },
                {
                    url: decodeURIComponent(this._urlUtils.getNoLangURL(relativeUrl)),
                    state: options.state ?? window.history.state,
                },
            );

            if (event) {
                this._logger.log(
                    'Stopping default browser behavior. Handling custom navigation...',
                );
                event.preventDefault();
            }

            if (
                this._viewInjector.ActiveView != ViewSource.FirstHit &&
                !(event instanceof PopStateEvent) &&
                ((this._clientProvider === ClientProvider.Kambi &&
                    !this._routerUtils.isClientNavigation(relativeUrl)) ||
                    this._clientProvider === ClientProvider.Spectate ||
                    this._clientProvider === ClientProvider.Poker)
            ) {
                if (options.isReplaceState) {
                    this._urlUtils.historyReplaceState(
                        this._window.document.title,
                        this._urlUtils.getLangURL(relativeUrl),
                    );
                } else {
                    this._urlUtils.historyPushState(
                        {},
                        this._window.document.title,
                        this._urlUtils.getLangURL(relativeUrl),
                    );
                }
            }

            this._sendNavigationRequestEvent(relativeUrl);
            await this._handleNavigation(relativeUrl, options);
        } else
            this._logger.log(
                `Navigation to ${decodeURIComponent(url)} is not managed by RouterModule.`,
            );
    }

    private async _handleNavigation(url: string, options?: IUrlHandlerOptions): Promise<void> {
        if (!(await this._handleMainViewApp(url))) {
            this._logger.log(`Managing navigation for ${url}.`);

            const isClientNavigation = this._routerUtils.isClientNavigation(url);
            this._viewInjector.ResetView();

            if (await this._handleAppOpening(this._urlUtils.getNoLangURL(url))) {
                this._logger.log(`Calling ViewInjector's function called: HideClientAndContent`);
                this._viewInjector.SetActiveView(ViewSource.Content);
                this._viewInjector.HideClientAndContent();
                this._utils.ClearElementWithId(IdsOfElements.UcFullContent);

                return;
            } else {
                this._viewInjector.ShowClientAndContent(isClientNavigation);
            }

            if (isClientNavigation) {
                await this._viewInjector.ShowClient(url, options);
            } else {
                const appCatalog = await this._appCatalogPromise;
                const appNavigationData = await appCatalog.getAppNavigationData(
                    this._urlUtils.getNoLangURL(url),
                );
                if (!!appNavigationData) {
                    const { actionID, pageTitle } = appNavigationData;

                    await this._userareaService.executeOnload(() => {
                        this._viewInjector.ShowMainAppView(actionID, pageTitle);
                    });
                } else await this._viewInjector.ShowContent(url, options);
            }

            this._logger.log(
                `Calling ViewInjector's function called: ${
                    isClientNavigation ? 'ShowClient' : 'ShowContent'
                }`,
            );
        }
    }

    private async _handleAppOpening(url: string) {
        const surveyEnabled = this._featureAvailability.IsFeatureEnabled(Features.SURVEY);

        if (surveyEnabled) {
            const parsedUrl = parse(url);
            if (
                this._getSurveyUrls().some((surveyUrl) => {
                    const encodedSurveyUrl = this._urlUtils.getURLWithBase(encodeURI(surveyUrl));

                    return (
                        encodedSurveyUrl === parsedUrl.pathname ||
                        encodedSurveyUrl === parsedUrl.hash
                    );
                })
            ) {
                (await this._crmPromise).OpenSurvey('router.service');
                await this._setSurveyTitle(parsedUrl);
                return true;
            }
        }

        return false;
    }

    private _getSurveyUrls() {
        const surveyEnabled = this._featureAvailability.IsFeatureEnabled(Features.SURVEY);
        const { clientPage, oldClientPage } = PageContextManager.getSurveyData() || {};

        return (surveyEnabled && [clientPage, oldClientPage].filter((link) => !!link)) || [];
    }

    private async _setSurveyTitle(parsedUrl) {
        const { publicationId } = PageContextManager.getInfraData();

        const siteSegmentationContext = await this._siteSegmentationContext.getString();
        let { response, errorResponse } = await ContentApi.GetPageContent(
            parsedUrl.pathname,
            publicationId,
            siteSegmentationContext,
        );
        document.title = response?.title || '';
    }

    private _isManagedNavigation(url: string, target?: string): boolean {
        if (!url) return false;
        const parsedURL = parse(url);

        if (!parsedURL) return false;

        if (target === '_blank') return false;

        if (parsedURL.hostname && parsedURL.hostname !== this._window.location.hostname)
            return false;

        return !(!!parsedURL.hash && !parsedURL.pathname);
    }

    private _isSameLocation = (parsedUrl) => {
        if (parsedUrl.hostname && parsedUrl.hostname !== this._window.location.hostname)
            return false;

        const urlWithoutHost = this._urlUtils.getURLWithoutHost(parsedUrl.href);
        const currentLocation = this._urlUtils.getURLWithoutHost(this._window.location.href);

        return encodeURI(urlWithoutHost) === currentLocation;
    };

    private async _handleMainViewAppClosing(parsedUrl?) {
        const currentLocation = !!parsedUrl
            ? this._urlUtils.getNoLangURL(parsedUrl?.href)
            : this._urlUtils.getNoLangURL(
                  this._urlUtils.getURLWithoutHost(this._window.location.href),
              );

        const appCatalog = await this._appCatalogPromise;
        const appNavigation = await appCatalog.getAppNavigationData(currentLocation);

        if (!!appNavigation) {
            this._sessionManagerChannel.topics.appClosing.publish({ publisher: 'RouterService' }, {
                appID: appNavigation?.appID,
            } as IAppClosedTopicPayload);
        }
    }

    private async _sendNavigationRequestEvent(url: string) {
        const biEventHandler = await this._biEventHandlerPromise;
        biEventHandler?.sendNavigationRequestEvent(url);
    }

    private async _closeOverlappingApps(url: string) {
        const webLoginService = Container.get(WebLoginService);
        webLoginService.Hide();
        this._commonService.CloseWrapDiv();
        const channel = {
            source: 'Unified Client',
            area: 'Overlay',
        };
        const appLauncher = await this._appLauncherPromise;
        appLauncher.CloseOpenedApps(CloseReason.userClosedApp, channel);
        const logoDiv = document.getElementById('logo');
        if (logoDiv) logoDiv.click();
        var userMenu = document.getElementsByClassName(
            'mobileAcUserMenu mobileAcUserMenuShow do-not-close-account-drawer',
        )[0];
        if (!!userMenu) {
            const userMenuCloseButton = document.getElementsByClassName(
                'aCuserMenuClose',
            )[0] as HTMLElement;
            if (userMenuCloseButton) userMenuCloseButton.click();
        }
        if (!this._routerUtils.isClientNavigation(url)) {
            const betSlipClose = document.getElementById('betslip-close');
            if (betSlipClose) betSlipClose.click();
        }
    }

    //#endregion Private
}
