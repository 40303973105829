import { Container, Service } from 'typedi';
import { LoggerProvider } from '../logger';
import { ILogger, ILoginSuccessData } from '@sparkware/uc-sdk-core';
import { WalletService } from './wallet.service';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import { SessionSimpleStoreService } from '../storage/implementations/simple-store';
import { LoaderManager } from '../../loaders/LoaderManager';
import { INativeService } from '../native/models/INativeService';

@Service()
export class Wallet {
    private readonly _logger: ILogger;
    private readonly _walletService: WalletService;
    private readonly _sessionSimpleStoreService: SessionSimpleStoreService;

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    public constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('Wallet');
        this._walletService = Container.get(WalletService);
        this._sessionSimpleStoreService = Container.get(SessionSimpleStoreService);
    }

    public onLoginSuccess = (data: ILoginSuccessData) => {
        if (data?.InitialState?.userContext) {
            const balanceData = {
                AvailableOnBankrollCurrency: data.InitialState.userContext.userBalance,
                BonusBalance: data.InitialState.userContext.bonusBalance,
                RealUserBalance: data.InitialState.userContext.realBR,
            };

            this._walletService.sendBalanceUpdated(balanceData);
        }
    };

    public onBetSuccess = async () => {
        try {
            const isBalancePollingRequired = this._sessionSimpleStoreService.get(
                StorageItemEnum.BalancePollingRequired,
            );
            if (isBalancePollingRequired == 'true') {
                await this._walletService.sendCurrentUserBalanceUpdated();
            }
            const nativeService = await this._nativeServicePromise;
            if (nativeService) {
                if (window['webkit']) {
                    window['webkit']?.messageHandlers?.callbackHandler?.postMessage({
                        function_name: 'fireAppTrigger',
                    });
                }
                if (nativeService.isAndroid && typeof WrapperInterface.betPlaced === 'function') {
                    WrapperInterface.betPlaced();
                }
            }
        } catch (err) {
            this._logger.error(`[_onBetSuccess] failed`, err);
            throw err;
        }
    };

    public onUpdateBalance = async () => {
        this._logger.debug('Begin process update balance topic request');

        await this._walletService.sendCurrentUserBalanceUpdated();

        this._logger.debug('End in processing update balance topic request');
    };
}
