import Container, { Service } from 'typedi';
import { UserareaLibrary } from 'library-manager';

import { LoggerProvider } from '../../logger';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class UserareaService {
    private readonly _logger: ILogger;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('UserareaService');
    }

    public executeOnload = async (callback: () => any) => {
        return UserareaLibrary.ready.then(() => callback());
    };

    public findIfIsLoaded = (): boolean => {
        return UserareaLibrary.isReady;
    };
}
