import Container, { Service } from 'typedi';
import { LoggerProvider } from '../logger';

import { AppIdentifiers, ILogger } from '@sparkware/uc-sdk-core';
import ClickStreamApi from '../../../APIs/ClickStreamApi';
import { EventType, IEventParams, Level } from '@unified-client/event-formatter/dist/models';
import { IEvent } from '@unified-client/event-formatter';
import { ProductPackageToID } from '@sparkware/uc-sdk-core';
import { UserContextToken } from '../../injection-tokens';
import { IUserContext } from '../user-context/user-context-interface';
import { LocalSimpleStoreService } from '../storage/implementations/simple-store';
import PageContextManager from 'page-context-manager';
import { Utils } from '../utils';
import { IUCEventHeader } from './models/interfaces/IUCEventHeader';
import { IUCEvent } from './models/interfaces/IUCEvent';
import { UrlUtils } from '../utils/urlUtils';
import { TrackingUtilsService } from './tracking-utils.service';
import { IElasticEventData } from './models/interfaces/IElasticEventData';
import { EventFormatter } from '@unified-client/event-formatter/dist/event-formatter';
import { v4 as uuIdV4 } from 'uuid';

@Service()
export class ClickStreamTrackingProvider {
    private _worker: Worker;
    private readonly _appID: AppIdentifiers;
    private readonly _localSimpleStoreService: LocalSimpleStoreService;
    private readonly _logger: ILogger;
    private readonly _userContext: IUserContext;
    private readonly _utils: Utils;
    private readonly _urlUtils: UrlUtils;

    private readonly _trackingUtilsService: TrackingUtilsService;

    public get SequentialNumber(): number {
        return this._getSequentialNumber();
    }

    public set SequentialNumber(value) {
        this._localSimpleStoreService.set('sequentialNumber', JSON.stringify(value));
    }

    private get IsWorkerEnabled(): boolean {
        return !!this._worker;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('ClickStreamTrackingProvider');
        this._userContext = Container.get(UserContextToken);
        this._localSimpleStoreService = Container.get(LocalSimpleStoreService);
        this._utils = Container.get(Utils);
        this._urlUtils = Container.get(UrlUtils);
        this._trackingUtilsService = Container.get(TrackingUtilsService);

        const isNativeSdk = this._utils.isNativeSDK();
        this._appID = isNativeSdk
            ? AppIdentifiers.UnifiedClientNative
            : AppIdentifiers.UnifiedClient;

        this._initWorker();
        this.sendEventV2 = this.sendEventV2.bind(this);
    }

    public sendEventV2<T>(
        event: IEvent<T, 'UCEvent' | string>,
        eventPromise?: Promise<void>,
    ): void {
        try {
            const enrichedEvent = this._enrichEvent(event);
            if (!eventPromise) {
                this._sendReport(enrichedEvent);
            } else {
                eventPromise.then(() => this._sendReport(enrichedEvent));
            }
        } catch (error) {
            this._logger.error('sendEventV2: Error while sending tracking event:', error);
        }
    }

    public resetSequentialNumber = () => {
        this.SequentialNumber = 0;
    };

    public sendElasticEvent = (
        formatter: EventFormatter<Partial<IEventParams>, {}>,
        message: string,
        eventData: IElasticEventData,
        error = null,
        correlationID: string = uuIdV4(),
        level = Level.information,
    ): void => {
        const trackingEvent = formatter.formatUCEvent(
            { message },
            { correlationID, level: level },
            { ...eventData },
            error,
        );
        this.sendEventV2(trackingEvent);
    };

    private _enrichEvent<T>(event: IEvent<T, 'UCEvent' | string>): IEvent<T, 'UCEvent' | string> {
        if (event && event.eventHeader) {
            const nativeUXVersion = this._urlUtils.getNativeUXVersion();
            const nativeOS = this._localSimpleStoreService.get('NativeOS');
            const nativeOSVersion = this._localSimpleStoreService.get('NativeOSVersion');

            const eventHeader: IUCEventHeader<'UCEvent' | string> = Object.assign(
                event.eventHeader,
                {
                    clientProvider: PageContextManager.getClientProviderData().name,
                    appID: this._appID,
                    nativeUXVersion: nativeUXVersion > 0 ? nativeUXVersion : undefined,
                    productPackageID:
                        event.eventHeader.productPackageID ??
                        PageContextManager.getDeviceData().productPackage?.toString(),
                    nativeOS,
                    nativeOSVersion,
                },
            );

            if (eventHeader.eventType?.type?.some((type) => type === EventType.Analytics)) {
                const newSequentialNumber = ++this.SequentialNumber;
                this._logger.debug(
                    `enrichEvent: eventType: ${event.eventHeader.eventType.name}, sequentialNumber: ${newSequentialNumber}`,
                );

                eventHeader.sequentialNumber = newSequentialNumber;
            }

            if (this._userContext.BatchNumber) {
                eventHeader.batchNumber = this._userContext.BatchNumber;
            }
        }

        if (event?.eventData && this._userContext.BatchNumber) {
            Object.assign(event.eventData, { batchNumber: this._userContext.BatchNumber });
        }

        //Add ClientProvider to event Data
        if (!!event?.eventData?.UCEvent) {
            (event.eventData.UCEvent as any).clientProvider =
                PageContextManager.getClientProviderData().name;
        }

        const deviceData = PageContextManager.getDeviceData();
        const eventData: IUCEvent<T, 'UCEvent' | string> = {
            ...event,
            sessionData: this._trackingUtilsService.getLogV2SessionData(),
            userData: this._trackingUtilsService.getLogV2UserData(),
            deviceData,
        };

        return eventData;
    }

    private _getSequentialNumber = (): number => {
        try {
            return parseInt(this._localSimpleStoreService.get('sequentialNumber')) || 0;
        } catch {
            this._localSimpleStoreService.set('sequentialNumber', '0');
            return 0;
        }
    };

    private _initWorker = () => {
        const clickStreamURL = PageContextManager.getUrlResourceData().clickStreamUrl;
        const { productPackage } = PageContextManager.getDeviceData();
        const isNativeSdk = this._utils.isNativeSDK();

        if (!!clickStreamURL && Worker) {
            try {
                this._worker = new Worker('/Scripts/trackingWorker.js');

                this._worker.onerror = (error) => {
                    this._logger.warn(
                        `Failed to initialize the worker. Messages will be handled in the old fashioned way. Error: ${error}`,
                    );

                    this._worker = null;
                };
            } catch (error) {
                this._logger.warn(
                    `Failed to initialize the worker. Messages will be handled in the old fashioned way. Error: ${error}`,
                );

                this._worker = null;
            }
            this._worker.postMessage({
                type: 'init',
                data: {
                    clickStreamURL,
                    appID: this._appID,
                    productPackageID: isNativeSdk
                        ? ProductPackageToID.UNIFIED_CLIENT_NATIVE.toString()
                        : productPackage.toString(),
                },
            });
        }
    };

    private _sendReport<T>(data: IEvent<T, 'UCEvent' | string>): void {
        if (this.IsWorkerEnabled) {
            const sanitizeData = this.sanitizeObject(data);
            this._worker.postMessage({ type: 'log', data: sanitizeData });
        } else {
            ClickStreamApi.SendReport(data);
        }
    }

    private sanitizeObject = (data) => {
        const newObject = {};

        if (typeof data === 'object' && data) {
            Object.assign(newObject, data);

            Object.keys(newObject).forEach((key) => {
                if (typeof newObject[key] === 'function') {
                    newObject[key] = newObject[key].toString();
                }
                if (typeof newObject[key] === 'object' && Array.isArray(newObject[key])) {
                    newObject[key] = newObject[key].map((elem) => this.sanitizeObject(elem));
                } else if (typeof newObject[key] === 'object' && newObject[key]) {
                    newObject[key] = Object.assign({}, this.sanitizeObject(newObject[key]));
                }
            });
        } else {
            return data;
        }
        return newObject;
    };
}
