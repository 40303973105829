import Container, { Service } from 'typedi';
import { LoggerProvider } from '../logger';
import { IUserContext } from './user-context-interface';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export abstract class UserContextBase implements IUserContext {
    protected _batchNumber?: number;
    protected _authorizationToken: string;

    protected readonly _logger: ILogger;

    abstract setAuthorizationToken(token?);
    abstract refresh();

    public get BatchNumber(): number {
        return this._batchNumber;
    }

    public get IsAuthenticated() {
        return !!this._authorizationToken;
    }

    protected constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('UserContext');
        this._logger.log('Initializing UserContext...');
    }
}
