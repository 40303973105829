import Container, { Service } from 'typedi';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
    Level,
} from '@unified-client/event-formatter';
import { LoggerProvider } from '../logger';
import { ClickStreamTrackingProvider } from '../tracking';
import { IPerformanceManagerResult } from './interfaces/IPerformanceManagerResult';
import { PerformanceToken } from '../../injection-tokens';
import { Utils } from '../utils';
import StringUtils from '../../../Modules/Utils/StringUtils';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class PerformanceManager {
    private _performance: Performance;
    private _clickStreamTrackingProvider: ClickStreamTrackingProvider;
    private _logger: ILogger;
    private _utils: Utils;
    private _eventFormatterBuilder: EventFormatterBuilder;

    constructor() {
        this._performance = Container.get(PerformanceToken);
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
        this._logger = Container.get(LoggerProvider).getLogger('PerformanceManager');

        this._utils = Container.get(Utils);
        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder =
            eventFormatterBuilderFactory.createEventFormatterBuilder('performance-manager');
    }

    public startWatch(watchName: string): void {
        this._performance.mark(watchName);
        this._logger.log(`Started watch '${watchName}'`);
    }

    public isStartedWatch(watchName: string): boolean {
        const watches = this._performance.getEntriesByName(watchName, 'mark');

        return watches.length > 0;
    }

    public getDuration(measureName: string, fromWatchName?: string): number {
        const PAGE_OPEN = 'PAGE_OPEN';
        this._logger.log(
            `Measuring duration for measure '${measureName}' and watch '${
                fromWatchName || PAGE_OPEN
            }'`,
        );

        this._performance.measure(measureName, fromWatchName);

        const performanceMeasure = this._performance.getEntriesByName(measureName).pop();

        if (!!performanceMeasure) {
            this._performance.clearMeasures(measureName);

            return performanceMeasure.duration;
        }

        this._logger.error(
            `Failed to measure duration for measure '${measureName}' and watch '${
                fromWatchName || PAGE_OPEN
            }'`,
        );

        return null;
    }

    public clearWatches(performanceWatchesNames: Array<string>): void {
        performanceWatchesNames.forEach((watchName) => {
            var watchEntries = this._performance.getEntriesByName(watchName);
            if (watchEntries.length > 0) {
                this._performance.clearMarks(watchName);
                this._logger.log(`Stopped watch '${watchName}'`);
            }
        });
    }

    public sendFailedReport(
        measureName: string,
        watchName: string,
        elasticEvent: string,
        errorCode: string,
        errorDescription: string,
        clearWatch: boolean = false,
    ) {
        const duration = this.getDuration(measureName, watchName);

        if (!!duration) {
            const correlationID = this._utils.getCorrelationId();
            const formatter = this._eventFormatterBuilder.createFormatter('sendFailedReport');

            const event = formatter.formatUCEvent(
                { message: elasticEvent, durationInMS: duration },
                { correlationID, level: Level.error },
                {
                    errorCode: !errorCode || isNaN(Number(errorCode)) ? 0 : Number(errorCode),
                    errorCodeString: StringUtils.toString(errorCode),
                    errorMessage: StringUtils.toString(errorDescription),
                },
            );

            if (clearWatch) this.clearWatches([watchName]);

            this._clickStreamTrackingProvider.sendEventV2(event);
        }
    }

    public sendReport(
        measureName: string,
        watchName: string,
        elasticEvent: string,
        clearWatch: boolean = false,
    ): void {
        const duration = this.getDuration(measureName, watchName);

        if (!!duration) {
            const correlationID = this._utils.getCorrelationId();
            const formatter = this._eventFormatterBuilder.createFormatter('sendReport');
            const event = formatter.formatUCEvent(
                { message: elasticEvent, durationInMS: duration },
                { correlationID },
            );

            if (clearWatch) this.clearWatches([watchName]);

            this._clickStreamTrackingProvider.sendEventV2(event);
        }
    }

    public measurePerformance<T>(
        method: () => T,
        measureName: string,
        fromWatchName?: string,
    ): IPerformanceManagerResult<T> {
        if (fromWatchName) {
            this.startWatch(fromWatchName);
        }

        const methodResult = method();
        const duration = this.getDuration(measureName, fromWatchName);

        const result: IPerformanceManagerResult<T> = {
            duration: duration,
            result: methodResult,
        };

        if (fromWatchName) {
            this.clearWatches([fromWatchName]);
        }

        return result;
    }

    public async measurePerformanceAsync<T>(
        method: () => Promise<T>,
        measureName: string,
        fromWatchName?: string,
    ): Promise<IPerformanceManagerResult<T>> {
        if (fromWatchName) this.startWatch(fromWatchName);

        const methodResult = await method();
        const duration = this.getDuration(measureName, fromWatchName);

        const result: IPerformanceManagerResult<T> = {
            duration: duration,
            result: methodResult,
        };

        if (fromWatchName) {
            this.clearWatches([fromWatchName]);
        }

        return result;
    }
}
