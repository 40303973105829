import Container, { Service } from 'typedi';
import {
    SessionChannelSubscriber,
    IdentityChannelSubscriber,
    NavigationChannelSubscriber,
    WalletChannelSubscriber,
    PlayerChannelSubscriber,
    GeolocationChannelSubscriber,
    NativeDocumentEventsSubscriber,
    NativeChannelSubscriber,
    BiometricsChannelSubscriber,
    LoyaltyIndicationChannelSubscriber,
    WindowEventsSubscriber,
    PaymentsChannelSubscriber,
    UIChannelSubscriber,
    CRMChannelSubscriber,
    CrossSellChannelSubscriber,
    ClientIntegrationChannelSubscriber,
    PostMessageChannelSubscriber,
    SportChannelSubscriber,
    UserInteractionChannelSubscriber,
    TrackingChannelSubscriber,
    DocumentEventsSubscriber,
} from './mb-channels';
import { Utils } from '../utils';

@Service()
export class GlobalSubscriber {
    private readonly _utils: Utils;
    constructor() {
        Container.get(SessionChannelSubscriber);
        Container.get(IdentityChannelSubscriber);
        Container.get(NavigationChannelSubscriber);
        Container.get(WalletChannelSubscriber);
        Container.get(PlayerChannelSubscriber);
        Container.get(GeolocationChannelSubscriber);
        Container.get(UIChannelSubscriber);
        Container.get(LoyaltyIndicationChannelSubscriber);
        Container.get(WindowEventsSubscriber);
        Container.get(PaymentsChannelSubscriber);
        Container.get(CRMChannelSubscriber);
        Container.get(CrossSellChannelSubscriber);
        Container.get(ClientIntegrationChannelSubscriber);
        Container.get(PostMessageChannelSubscriber);
        Container.get(SportChannelSubscriber);
        Container.get(UserInteractionChannelSubscriber);
        Container.get(TrackingChannelSubscriber);

        this._utils = Container.get(Utils);
        if (this._utils.findIfIsNative()) {
            Container.get(NativeDocumentEventsSubscriber);
            Container.get(NativeChannelSubscriber);
            Container.get(BiometricsChannelSubscriber);
        } else {
            Container.get(DocumentEventsSubscriber);
        }
    }
}
