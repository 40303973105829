import Container, { Service } from 'typedi';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
} from '@unified-client/event-formatter';
import { LoggerProvider } from '../../logger';
import { ClickStreamEventInnerType } from '../enums/consts';
import { ClickStreamTrackingProvider } from '../ClickStreamTrackingProvider';
import { ITrackingEvent } from './ITrackingEvent';
import { ITrackingProvider } from './interfaces';
import { Utils } from '../../utils';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class AnalyticsElasticTracking implements ITrackingProvider {
    private _clickStreamTrackingProvider: ClickStreamTrackingProvider;
    private _logger: ILogger;
    private _eventFormatterBuilder: EventFormatterBuilder;
    private _utils: Utils;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('AnalyticsElasticTracking');
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);

        this._utils = Container.get(Utils);
        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder = eventFormatterBuilderFactory.createEventFormatterBuilder(
            'AnalyticsElasticTracking',
        );
    }

    track(eventData: ITrackingEvent, correlationID: string, subComponentName: string): void {
        if (!eventData) return;

        if (!correlationID) correlationID = this._utils.getCorrelationId();

        const formatter = this._eventFormatterBuilder.createFormatter(subComponentName || 'track');

        const event = formatter.formatUCEvent(
            { message: eventData.event, innerType: ClickStreamEventInnerType.Analytics },
            { correlationID },
            eventData,
        );

        this._logger.log('Send event with elastic - clickstream');
        this._clickStreamTrackingProvider.sendEventV2(event);
    }
}
