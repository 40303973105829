import Container, { Service } from 'typedi';
import { ClientsFrameworkLibrary } from 'library-manager';

import { LoggerProvider } from '../../logger';
import { IClientsFrameworkOpenServiceProductService } from './interfaces';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class ClientsFrameworkOpenServiceProductService
    implements IClientsFrameworkOpenServiceProductService
{
    private readonly _logger: ILogger;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger(
            'ClientsFrameworkOpenServiceProductService',
        );
    }

    public clientAutoLogin = async (
        subBrandId,
        productPackageId,
        additionalActivationParameters,
        width,
        height,
        onClose,
        linkId?,
        target?,
        openInMobileContainer?,
        showCloseButton?,
        paramsToAdd?,
        getUrl?,
        functionToInvoke?,
    ) => {
        await ClientsFrameworkLibrary.ready;
        return PF.Web.ClientsFramework.OpenServiceProductPackage.clientAutoLogin(
            subBrandId,
            productPackageId,
            additionalActivationParameters,
            width,
            height,
            onClose,
            linkId,
            target,
            openInMobileContainer,
            showCloseButton,
            paramsToAdd,
            getUrl,
            functionToInvoke,
        );
    };
}
