import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { ILogger, ITopicEnvelope, ITrackingChannel, MessageBroker } from '@sparkware/uc-sdk-core';
import { ClientTracking } from '../../tracking/models/clientTracking';

@Service()
export class TrackingChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _trackingChannel: ITrackingChannel;
    private readonly _clientTracking: ClientTracking;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('ClientTrackingChannelSubscriber');
        this._clientTracking = Container.get(ClientTracking);
        this._trackingChannel = MessageBroker.getInstance().tracking;
        this._trackingChannel.topics.sendAnalytics.subscribe(this.onSendAnalytics.bind(this));
        this._trackingChannel.topics.sendEvent.subscribe(this.onSendEvent.bind(this));
    }

    private async onSendAnalytics(trackingInfo: any, envelope: ITopicEnvelope) {
        this._clientTracking.onSendAnalytics(trackingInfo, envelope);
    }

    private async onSendEvent(inputData: any, envelope: ITopicEnvelope) {
        this._clientTracking.onSendEvent(inputData, envelope);
    }
}
