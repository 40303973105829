import Container, { Service } from 'typedi';
import { ClientsFrameworkLibrary } from 'library-manager';

import { LoggerProvider } from '../../logger';
import { IClientsFrameworkInitService } from './interfaces';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class ClientsFrameworkInitService implements IClientsFrameworkInitService {
    private readonly _logger: ILogger;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('ClientsFrameworkInitService');
    }

    public refreshDataStore = async (forceSet: boolean, onSuccess: Function): Promise<void> => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.Init.refreshDataStore(forceSet, onSuccess),
        );
    };

    public setAuthenticatedSessionData = async (
        forceSet: boolean,
        onDone: Function,
    ): Promise<void> => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.Init.setAuthenticatedSessionData(forceSet, onDone),
        );
    };

    public RealityCheck = async () => {
        await ClientsFrameworkLibrary.ready.then(() => PF.Web.ClientsFramework.Init.RealityCheck());
    };

    public SetLoginNotification = async () => {
        await ClientsFrameworkLibrary.ready.then(() =>
            PF.Web.ClientsFramework.Init.SetLoginNotification(),
        );
    };
}
