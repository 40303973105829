import { LoggerProvider } from '../../logger';
import { TouchIdService } from '../../native/touch.id.service';
import Container, { Service } from 'typedi';
import { ILogger, INativeChannel, MessageBroker } from '@sparkware/uc-sdk-core';
import { INativeService } from '../../native/models/INativeService';
import { LoaderManager } from '../../../loaders/LoaderManager';

@Service()
export class NativeChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _nativeChannel: INativeChannel;
    private readonly _touchIdService: TouchIdService;

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('NativeChannelSubscriber');
        this._nativeChannel = MessageBroker.getInstance().native;
        this._touchIdService = Container.get(TouchIdService);

        this._nativeChannel.topics.TouchIdPostLoginFlow.subscribe(
            this.onTouchIdPostLoginFlow.bind(this),
        );
        this._nativeChannel.topics.ResetTouchId.subscribe(this.onResetTouchId.bind(this));
        this._nativeChannel.topics.SaveTouchIdToken.subscribe(this.onSaveTouchIdToken.bind(this));
        this._nativeChannel.topics.SaveSuggestedStatus.subscribe(
            this.onSaveSuggestedStatus.bind(this),
        );
    }

    private onTouchIdPostLoginFlow() {
        this._touchIdService.touchIdPostLoginFlow();
    }
    private onResetTouchId() {
        this._touchIdService.resetTouchId();
    }

    private onSaveTouchIdToken(data: any) {
        if (data) {
            this._touchIdService.saveTouchIdToken(data.callback);
        }
    }

    private onSaveSuggestedStatus() {
        this._touchIdService.saveSuggestedStatus();
    }
}
