import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import DocumentHelper from '../../../../Modules/Utils/DocumentHelper';
import { ILogger } from '@sparkware/uc-sdk-core';
import { IWebPushService } from '../../web-push/models/IWebPushService';
import { LoaderManager } from '../../../loaders/LoaderManager';

@Service()
export class DocumentEventsSubscriber {
    private readonly _logger: ILogger;

    private get _webPushServicePromise(): Promise<IWebPushService> {
        return LoaderManager.Instance.WebPushLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('DocumentEventsSubscriber');
        DocumentHelper.onDomReady(this._onDomReady);
    }

    private _onDomReady = async () => {
        const webPushService = await this._webPushServicePromise;
        await webPushService?.onDomReady();
        this._logger.debug(`[_onDomReady]] finished`);
    };
}
