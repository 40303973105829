import { ClientIntegrationFacadeToken, WindowToken } from '../../injection-tokens';
import { Container, Service } from 'typedi';
import { BiometricsUtils } from '../biometrics/biometrics.utils';
import { MessageBroker, NativeChannel, UiChannel } from '@sparkware/uc-sdk-core';
import { WebLoginService } from './web.login.service';
import { IClientIntegrationFacade } from '../client-integration/interfaces/IClientIntegrationFacade';

@Service()
export class WebLogin {
    private _nativeChannel: NativeChannel;
    private _uiChannel: UiChannel;
    private _biometricsUtils: BiometricsUtils;
    private readonly _window: Window;
    private readonly _webLoginService: WebLoginService;
    private readonly _clientIntegrationFacade: IClientIntegrationFacade;

    constructor() {
        this._window = Container.get(WindowToken);
        this._webLoginService = Container.get(WebLoginService);
        this._biometricsUtils = Container.get(BiometricsUtils);
        this._clientIntegrationFacade = Container.get(ClientIntegrationFacadeToken);
        this._nativeChannel = MessageBroker.getInstance().native;
        this._uiChannel = MessageBroker.getInstance().ui;

        this._nativeChannel.topics.isFirstTouchLogin.subscribe(this._onIsFirstTouchLogin);
        this._uiChannel.topics.resetLoginForm.subscribe(this._onResetLoginForm);
        this._uiChannel.topics.checkWebLoginIsShown.subscribe(this._onCheckWebLoginIsShown);
        this._uiChannel.topics.webLoginShow.subscribe(this._onWebLoginShow);
    }

    Init = () => {
        this._window['rllogin'] = this._webLoginService.TempWebLoginObject;

        const options = {
            once: true,
            passive: true,
        };
        const scrollOptions = {
            once: true,
            capture: true,
            passive: true,
        };
        this._window.addEventListener('click', this._webLoginService.GetWebLogin, options);
        this._window.addEventListener('scroll', this._webLoginService.GetWebLogin, scrollOptions);
        this._window.addEventListener('touchStart', this._webLoginService.GetWebLogin, options);
    };

    private _onIsFirstTouchLogin = (isFirstTouchLogin: boolean): void => {
        this._webLoginService.IsFirstTouchLogin = isFirstTouchLogin;
    };

    private _onResetLoginForm = (): void => {
        this._webLoginService.ResetLoginForm();
    };

    private _onCheckWebLoginIsShown = (): void => {
        this._clientIntegrationFacade.webLoginIsShownPublish(this._webLoginService.IsShown);
    };

    private _onWebLoginShow = async (data: { callback: () => void }): Promise<any> => {
        await this._webLoginService.Show();
        if (typeof data?.callback === 'function') data.callback();
    };
}
