import Container, { Service } from 'typedi';
import DeferredObject from '../../../../Modules/Utils/DeferredObject';
import { LoggerProvider } from '../../logger';
import { ILogger } from '@sparkware/uc-sdk-core';

@Service()
export class PageContentManager {
    private _footerDO: DeferredObject<string>;
    private _contentDO: DeferredObject<string>;
    private _titleDO: DeferredObject<string>;
    private _logger: ILogger;

    public get FooterDO(): DeferredObject<string> {
        return this._footerDO;
    }

    public get ContentDO(): DeferredObject<string> {
        return this._contentDO;
    }

    public get TitleDO(): DeferredObject<string> {
        return this._titleDO;
    }

    constructor() {
        this._footerDO = new DeferredObject({ storeResolved: true });
        this._contentDO = new DeferredObject({ storeResolved: true });
        this._titleDO = new DeferredObject({ storeResolved: true });
        this._logger = Container.get(LoggerProvider).getLogger('PageContentManager');
    }

    public reset = (includeFooter = false): void => {
        if (includeFooter) {
            this._footerDO.promise.catch((err) => {
                this._logger.warn(`reset footerDO:`, err);
            });
            this._footerDO.reject('');
        }

        if (!this._contentDO.resolved) {
            this._contentDO.promise.catch((err) => {
                this._logger.warn(`reset contentDO:`, err);
            });
            this._contentDO.reject('cancelled');
        }

        if (!this._titleDO.resolved) {
            this._titleDO.promise.catch((err) => {
                this._logger.warn(`reset titleDO:`, err);
            });
            this._titleDO.reject('cancelled');
        }

        if (includeFooter) {
            this._footerDO = new DeferredObject({ storeResolved: true });
        }

        this._contentDO = new DeferredObject({ storeResolved: true });
        this._titleDO = new DeferredObject({ storeResolved: true });
    };

    public resolveFooter = (footer: string) => {
        this._footerDO.resolve(footer);
    };

    public resolveContent = (content: string) => {
        this._contentDO.resolve(content);
    };

    public resolveTitle = (title: string) => {
        this._titleDO.resolve(title);
    };
}
