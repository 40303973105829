import { Container, Service } from 'typedi';
import { LoggerProvider } from '../logger';
import {
    IUserInteractionChannel,
    MessageBroker,
    IHapticFeedbackData,
    ILogger,
} from '@sparkware/uc-sdk-core';
import { COMPONENT_NAME } from '../../models/enums/Consts';
import { Utils } from '../utils';
import { INativeService } from '../native/models/INativeService';
import { LoaderManager } from '../../loaders/LoaderManager';

@Service()
export class UserInteraction {
    private readonly _utils: Utils;
    private readonly _logger: ILogger;
    private readonly _channel: IUserInteractionChannel;

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    public constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('UserInteraction');
        this._utils = Container.get(Utils);
        this._channel = MessageBroker.getInstance().userinteraction;
    }

    public burgerMenuClicked = () => {
        this._logger.debug('In burgerMenuClicked.');
        this._channel.topics.BurgerMenu_Toggle.publish(
            {
                publisher: COMPONENT_NAME,
            },
            {},
        );
    };

    public exitOffCanvasClicked = () => {
        this._logger.debug('In exitOffCanvasClicked.');
        this._channel.topics.BurgerMenu_Toggle.publish(
            {
                publisher: COMPONENT_NAME,
            },
            {},
        );
    };

    public onHapticFeedback = async (data: IHapticFeedbackData) => {
        const nativeService = await this._nativeServicePromise;

        if (nativeService) {
            if (data?.type) nativeService.triggerHapticFeedback(data.type);
            else this._logger.error('No haptic feedback type received');
        }
    };
}
